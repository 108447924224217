.search {
    display: flex;
        position: relative;
        align-items: center;
        padding-top: 17px;
        padding-bottom: 7px;

    .search-left-icon {
        margin-left: 16px;
        
        
    }

    .custom-search-input {
        width: 100%;
        outline: none;
        border-radius: 50px;
        font-size: 12px;
        padding: 4px 20px 4px 10px;
        margin-right: 10px;
    }
    .searchinput{
        position: relative;
        width: 85%;
    }

    .custom-search-input::placeholder {
        font-size: $xs-size;
    }

    .custom-search-inputicon {
      
        cursor: pointer;
        margin-top: 13px;
        position: absolute;
        right: 5px;
        top: -5px;
        margin-right: 16px;
    }
}

.listElements {
    padding: 8px 16px;

    .listElement-title {
        font-size: $xs-size;
    }

    ul {
        list-style-type: none;
        padding: unset;


        li {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100%;
            display: inline-block;
            font-size: $xs-size;
            margin: 3px;
            letter-spacing: -0.005em;
            padding: 4px 12px !important;
            box-shadow: 0 0.5px 1.5px rgba(0, 0, 0, .25882);

            a {
                text-decoration: none;
            }
        }

    }
}

.searchHistory-main-elements {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

}

.searchHistory-elements {
    padding: 5px 15px;
    width: -moz-fit-content;
    width: fit-content;
    /* border: 1px solid gray; */
    margin-right: 5px;
    color: gray;
    font-weight: 400;
    background: #F4F4F4;
    font-size: 12px;
    border-radius: 5px;
    cursor: pointer;
}

.search-history-title {

    font-size: 16px !important;
    font-weight: 500 !important;
}

.search-products {
    padding: 0px 10px;
}

.searchhistory-delete-button {
    // font-size: 16px;
    // font-weight: 500;
    // padding-right: 40px;
    color:  #D97474;
    background: #F9F9F9;
    padding: 5px 5px;
    display: flex
;
    justify-content: center;
    border-radius: 50px;


}

.search-heading {
    display: flex;
    flex-direction: row;
    margin: 10px 0px!important;
    padding-top: 10px !important;
    justify-content: space-between;
}
.searchtitle_search{
    font-size: 18px;
    font-weight: 700;
    padding: 0px 10px;
}