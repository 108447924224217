.breadcomp {
    display: flex;
    // font-size: 13px;
    // font-weight: 500;
    cursor: pointer;
    align-items: center;
    // color: $paragraph-color;
    @media only screen and (max-width: 991px) {
        overflow: scroll;
    }

    padding: 10px;

    .breadcomp-arrow {
        padding: 0px 10px;
        font-weight: 800;
        color: #A4A4A4;
        font-size: 16px;
    }

    .breadcomp-product-title {
        // max-width: 100px;
        white-space: nowrap;


        @media only screen and (max-width: 991px) {
            overflow: hidden;
        }

        text-overflow: ellipsis;
        display: inline-block;
        vertical-align: middle;

    }

    .breadcomp-product-titles-home {
        text-align: left;

    }

    .breadcomp-product-titles:hover {
        // color: $primary_color;
    }
}