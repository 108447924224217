.my-cart.row {
    margin-top: 15px;

    @media only screen and (max-width: 991px) {
        margin-bottom: 60px;
    }

    .my-cart-container {
        margin-bottom: 10px;

        .my-cart-heading {
            // font-size: 22px;
            // font-weight: 700;
            // color: #333;
            margin-right: 10px;

            span {
                // color: #999;
                // font-size: 18px;
                // font-weight: 400;
            }
        }
    }


    .coupon {
        // border: 1px dashed #b09975;
        padding: 20px 10px 10px;
        position: relative;
        // min-height: 130px;
        overflow-y: hidden;

        .coupon_header {
            // font-weight: 700;
            // font-size: 15px;
            // color: #333;
        }

        .input-area {
            width: 100%;
            margin-top: 15px;

            .coupanmsg {
                font-size: 14px;
                font-weight: 600;

                color: $primary_color;
            }

            .input-box-div {
                display: flex;

                #couponCodeInput {
                    // width: 100%;
                    // border: 1px solid #eee;
                    // height: 40px;
                    padding: 0 5px 0 10px;
                    // font-size: 14px;
                    // font-weight: 700;
                    outline: none;
                }

                .rilrtl-button {
                    padding: 0 10px;
                    // background-color: #f5f5f5;
                    border: none;
                    outline: none;
                    // font-size: 14px;
                    // font-weight: 500;
                    letter-spacing: 0.04em;

                    text-transform: uppercase;
                }
            }
        }


    }

    .my-cart-button {
        // background: $primary_color;
        border: none;
        border-radius: 5px;
        // color: #fff;
        // font-size: $s-size0;
        // font-weight: 600;
        text-align: center;
        cursor: pointer;
        margin-bottom: 20px;
        padding: 10px;
        width: 100%;
    }

    .btn-2 {
        background: #FFD814
    }

}

.cart-list-detail {
    // border: 1px solid #eee;
    padding: 10px;
    position: relative;
    background: #fff;
    // max-width: 950px;
    margin-bottom: 5px;


    .card-detail-firstrow {
        display: flex;

        .cart-detail-rightside {
            width: 100%;
            display: flex;
            margin-left: 10px;
            justify-content: space-between;
            align-items: baseline;

            // justify-content: space-between;
            // .cart-corner-rightside {
            // position: relative;
            // width: 100%;
            // cursor: pointer;

            .cart-delivery-expected_by {
                bottom: 15px;
                font-size: $s-size;
                right: 10px;
                color: $primary_color;
                font-weight: 500;
            }

            // }
        }

        .cart-detail-lefttside {
            // margin-right: 15px;
            margin: auto;

            .cehcekoutcartimg {
                // height: 50px !important;
                // width: 50px !important;
            }

            .cart-product-img {
                // height: 80px;
                // width: 80px;
                position: relative;
                margin: 0 auto;

                img {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    top: 0;
                    margin: auto;
                    opacity: 1;
                    max-width: 100%;
                    max-height: 100%;
                }
            }



        }
    }




    .cart-remove {
        margin-top: 6px;
        float: right;
        margin-right: 8px;
    }

    .cart-product-detail {
        // margin-left: 20px;
        // margin-top: 5px;

        .product-title {
            // color: #535766;
            // font-family: 'Poppins', sans-serif;
            // font-size: $s-size;

            // overflow: hidden;
            // text-overflow: ellipsis;
            // white-space: nowrap;
            max-width: 500px;
            // height: 20px;
            // font-weight: 400;

            // margin-top: 10px;
        }

        .product-price {
            align-items: center;
            display: flex;
            margin-top: 5px;

            .Paragraph-16-M--Semibold {
                // font-size: 16px;

                display: inline;
                // color: $black;
                // font-weight: 700;
                // font-family: $font-family-2;
            }

            .offer-revamp {
                color: $paragraph-color;
                display: inline-block !important;
                margin-left: 7px;
                text-decoration: line-through;
                font-family: $font-family-2;
            }

            .offer-label-revamp {
                // font-size: $s-size;
                // padding: 0px 3px;
                margin-left: 8px;
                margin-top: 5px;
                // color: $primary_color;
                // font-weight: 600;
            }
        }


    }
}

.cart-empty-icon {
    font-size: 100px;
    // color: $primary_color;
}

.savelater-image-subscription {
    width: 100%;

    .savelater-image-subscription-image {
        cursor: pointer;
        margin-top: 20px;
        margin-bottom: 20px;
        width: 100%;
        height: 140px;
        object-fit: cover;
    }
}

.vip-membership-main-div {
    cursor: pointer;
    display: flex;
    border: 1px solid #eee;
    padding: 0px 10px;



    margin: 20px 0px;

    .vip-membership-logo {

        .vip-membership-logo-image {
            width: 100px;
            height: 100px;
            padding: 5px;
        }
    }

    .vip-membership-content {


        flex-grow: 1;
        padding-top: 10px;
        padding-left: 5px;
        display: inline;
        text-overflow: ellipsis;
        overflow: hidden;

        .vip-membership-content-status {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: initial;
            display: -webkit-box;
            font-size: 14px;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            padding-bottom: 5px;
        }

        .vip-membership-content-price {
            font-size: 16px;

            display: inline;
            color: #000;
            font-weight: 700;
        }

        .vip-membership-planchange {
            padding-top: 5px;
            color: #ff9933;
            font-size: 14px;
            font-weight: 600;
        }
    }

    .vip-membership-content-remove-button {
        bottom: 15px;
        font-size: 14px;
        right: 10px;
        color: $primary_color;
        cursor: pointer;
        font-weight: 500;
        padding-top: 10px;
        padding-right: 10px;

    }
}

.payment_method {
    border: 1px solid #eee;
    padding: 20px 10px 10px;
    position: relative;
    min-height: 130px;
    overflow-y: hidden;
    // margin-bottom: 1rem ;
    // margin-top: 1.5rem ;

    .payment_header {
        // font-weight: 700;
        // font-size: 15px;
        // color: #333;
        margin-bottom: 10px;
        // @media only screen and (min-width: 991px) {
        //     margin-top: 20px;
        // }
    }

    .detail_row {
        padding: 8px 0;
        display: flex;
        width: 100%;
        border-bottom: 1px solid #e0e0e0;
        justify-content: space-between;

        &:last-of-type {
            border-bottom: none;
        }

        .detail_row_left {
            // font-weight: 500;
            text-transform: none;
            // font-size: 1rem;
            letter-spacing: -.08px;

        }
    }
}

.active-font-size-green {}

.active-font-size-black {
    font-weight: 700;
}

@media only screen and (max-width: 991px) {
    .cart-detail-payment {
        font-size: 16px;
        margin-top: 9px;
        padding: 12px;
        font-weight: 700;
        width: 100%;
    }

    .custom-cart-modalsheet.react-modal-sheet-container {
        height: auto !important;
    }
}

@media only screen and (max-width: 768px) {
    .cart-delivery-expected_by {
        display: none;
    }
}

@media only screen and (min-width: 600px) {
    .product-title {
        // color: $black;
        // font-size: $s-size0;
        // font-family: $font-family-2;

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        // width: 340px;
        // height: 20px;
        // font-weight: 400;
    }
}

@media only screen and (max-width: 1200px) {
    .my-cart-container {
        max-width: 1140px !important;
    }

    .cart-list-detail .cart-product-detail .product-title {
        // width: 450px;
    }

}

@media only screen and (max-width: 600px) {

    .product-title {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: initial;
        display: -webkit-box;
        font-size: $s-size;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }


    .cart-detail-lefttside {
        .cart-product-img {
            height: 70px !important;
            width: 70px !important;
        }
    }

    .card-detail-secondrow {
        .quantityContainer {
            .quantity {
                width: 30px;
            }
        }

        .card-detail-secondrow-rightside {
            ._3dsJAO {
                margin-right: 12px !important;
                font-size: $xs-size !important;

            }
        }
    }

    .cart-corner-rightside {
        // width: 25% !important;


        .cart-remove {
            bottom: 8px;
            position: absolute;
            right: 11px;
        }
    }
}

.custom-cart-modalsheet {
    border-top-right-radius: 40px !important;
    border-top-left-radius: 40px !important;

    .react-modal-sheet-content {
        margin-left: 10px;
        margin-top: 15px;
        margin-right: 10px;

        .payment_header {
            margin: 20px;


        }

        .product-detail-buttongroup {
            border-top: 1px solid #eee;
        }

        .modal-sheet {
            border: none;
        }
    }
}

.cartempty {
    img {
        height: auto;
        width: 110px;
    }

    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100vh;

    .cartempty-msg {
        font-size: $s-size0;
        font-weight: 600;
        margin-top: 20px;
    }

    .catrempty-content {
        font-size: 14px;

    }

    .catrempty-btn {

        width: fit-content;
        font-size: 14px;

        text-align: center;
        padding: 8px 31px;
        border-radius: 5px;
        margin-bottom: 20px;
        margin-top: 10px;
    }
}


.Save-for-later {
    font-size: 22px;
    font-weight: 700;
    color: #333;
    margin-right: 10px;
    margin-top: 20PX;
    padding-bottom: 10px;

    span {
        color: #999;
        font-size: 18px;
        font-weight: 400;
    }
}

.card-detail-secondrow {

    display: flex;
    align-items: baseline;
    margin-top: 10px;
    margin-bottom: 5px;
    // margin-top: 15px;

    .quantityContainer {
        display: flex;
        margin-top: 10px;
    }

    .plusButton {
        border: none;
        background-color: transparent;
        color: #333;
        font-size: 21px;
        cursor: pointer;
    }

    .quantity {
        display: inline-block;
        width: calc(100% - 60px);
        height: 100%;
        width: 46px;
        text-align: center;
        height: 28px;
        border-radius: 2px;
        background-color: #fff;
        border: 1px solid #c2c2c2;
        margin: 5px 8px;
    }

    .card-detail-secondrow-rightside {
        padding-left: 24px;

        ._3dsJAO {
            display: inline-block;
            text-transform: uppercase;
            box-shadow: none;
            outline: 0;
            // font-size: $s-size;
            // font-weight: 500;
            // border-right: 1px solid #ddd;
            cursor: pointer;
            margin-right: 10px;
            padding-right: 15px;
        }
    }



}

.card-detail-secondrow1 {

    display: flex;
    align-items: baseline;
    /* background: lightgray; */
    // color: black;
    // border: 1px solid lightgray;
    border-radius: 5px;
    margin-top: 3px;
    // margin-top: 15px;

    .quantityContainer1 {
        display: flex;
        // padding-top: 2px;
        // padding-left: 15px;
        // padding-right: 15px;
        // padding-bottom: 4px;
        padding: 0px 5px;

        justify-content: space-between;
        width: 100%;
    }


    .plusButton1 {
        // color: #fff;
        font-size: 18px;
        cursor: pointer;

        .plusButton1-btn-icon {
            width: 18px;
            height: 18px;
        }
    }

    .quantity1 {
        text-align: center;
        padding: 3px;
        // color: white;
    }
}

.cart-w-15 {
    width: 13% !important;

    @media screen and (max-width: 991px) {
        width: 31% !important;
    }
}

.product-variant_name {
    margin-top: 5px;
    font-family: $font-family-2;
    color: $black;
}

.checkbox-custom:checked:before {
    background-color: green;
}

.detail_row_right {
    // font-family: $font-family-2;
}



// ..............................vochers
.vochers_main {
    display: flex;
    flex-direction: column;
    border: 1px solid #F2B8B8;
    background: #FFEBEB;
    box-shadow: 0px 3px 6px 0px #00000029;
    border-radius: 5px;
    position: relative;
    margin-bottom: 10px;
}
.vocherballleft{
    position: absolute;
    top: 50%;
    left: -10px;
    width: 20px;
    background: #fff;
    height: 20px;
    top: 40px;
    border-radius: 50px;
    border-right: 1px solid #f81140;

}
.vocherballright{
    position: absolute;
    top: 50%;
    right: -10px;
    width: 20px;
    background: #fff;
    height: 20px;
    top: 40px;
    border-radius: 50px;
    border-left: 1px solid #f81140;
}
.vochersubmain{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    border-bottom: 1px dashed  #F81140;
}
.Vochername {
    font-family: Raleway;
    font-size: 17px;
    font-weight: 700;
    line-height: 19.96px;
    text-align: left;
    color: #F81140;
}
.voucherdate_remining{
    font-family: Raleway;
font-size: 11px;
font-weight: 500;
line-height: 12.91px;
text-align: left;
color: #F81140;
padding-right: 10px;

}
.voucherdate_reminingdate{
    font-family: Raleway;
font-size: 11px;
font-weight: 500;
line-height: 15px;
letter-spacing: -0.10999999940395355px;
text-align: center;
background: #F81140;
color: #fff;
border-radius: 5px;
padding: 2px 3px;

}
.vochertop{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.vocher_second_main{
    display: flex;
    flex-direction: column;
    align-items: start;
}
.vochersecond_div{
    font-family: Raleway;
    font-size: 17px;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: -0.17000000178813934px;
    text-align: left;
    color: #202020;   
}
.vochericon{
    color: #3C3271;
    margin-right: 5px;
}
.vocherprice{
    font-family: Nunito Sans;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    
}
.vocher_main_button{
    font-family: Raleway;
font-size: 14px;
font-weight: 500;
line-height: 18px;
letter-spacing: -0.14000000059604645px;
text-align: center;
text-underline-position: from-font;
text-decoration-skip-ink: none;
// background: #C23A97;
cursor: pointer;
// color: #fff;
height: fit-content;
padding: 3px;
border-radius: 5px;
}
.vochersecond{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: end;
}
.coupanmsgvocher{
  font-size: 12px;
  color: #33B36B;
  padding: 5px;

}

.coupanmin_ordermsg{
    font-size: 12px;
    padding: 5px;
}
.cartbottomsheet{
    position: relative;
}
.cartbottomsheetclose{
    position: absolute;
    top: 20px;
    right: 20px;
}