.productdetailimages {
  position: sticky;
  top: 130px;
  margin-left: 100px !important;

  @media screen and (max-width: 991px) {
    margin-left: 0px !important;
  }
}

.scroll-content-right {

  // padding-right: 0px !important;
  @media screen and (min-width: 991px) {
    padding-right: 0px !important;
  }
}

.product-rightside {
  margin: 30px 0px !important;

  @media screen and (max-width: 991px) {
    margin: 5px !important;
  }
}

.productdetail_breadcomp {
  position: sticky;
  top: 123px;
}

.product_desktop_margin {
  margin-right: 100px !important;

  @media screen and (max-width: 991px) {
    margin-right: 0px !important;
  }
}

.outer_div {
  display: flex;
  // position: relative;


  .scroll-content-left {
    position: sticky !important;
    top: 168px !important;

  }



  .scroll-content-right {
    height: 100%;
    right: 0;


    .product_Short_description {
      color: $paragraph-color;
      font-size: 15px;
      font-family: $font-family-2;
    }


    .productBank {
      margin: 12px 0px 0px 0px;

      .offerCard {
        font-weight: 400;
        font-size: $s-size;
        margin: 8px 0px;
        color: $black;

        .learnMore {
          background-color: transparent;
          border: none;
          color: #828282;
          cursor: pointer;
          font-size: $xs-size;
          font-weight: 400;
          text-decoration-line: underline;
        }
      }

      .viewMore {
        border: none;
        font-size: $xs-size;
        cursor: pointer;
        font-weight: 700;
      }
    }

    .tab-container::-webkit-scrollbar {

      width: 2.5px;
    }

    .tab-container::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 15px #FD7900;
    }

    .productBankOffer {
      font-weight: 700;
      font-size: $s-size;
      color: $black;
      margin-bottom: 8px;
    }

    .productBankOffers {
      font-weight: 700;
      font-size: $s-size;
      color: $black;
    }

    .product-price {
      align-items: center;
      display: flex;

      .Paragraph-16-M--Semibold {
        font-size: 20px;
        display: inline;
        font-weight: 500;
        color: $primary_color;
        font-family: $font-family-2;
      }

      .offer-revamp {
        color: $paragraph-color;
        display: inline-block !important;
        margin-left: 5px;
        font-size: 13px;
        text-decoration: line-through;
        font-family: $font-family-2;
      }

      .offer-label-revamp {
        font-size: 16px;
        padding: 0px 5px;
        margin-left: 5px;
        // margin-top: 5px;
        color: $primary_color;
        // font-weight: 600;
      }
    }
  }
}

.ratings {
  display: flex;
  margin-top: 3px;

  .product-ratingsContainer {
    display: inline-block;
    color: $white;
    padding: 5px;
    border-radius: 3px;
    // margin-top: 5px;
    font-weight: 400;
    font-size: $xs-size;
    vertical-align: middle;
    background-color: $primary_color;
  }

  .rating-count {
    padding-left: 8px;
    margin-top: 5px;
    font-weight: 500;
    font-size: $s-size;
    color: #878787;
  }
}







.mySwiper {
  margin-top: 20%;
  margin-bottom: 20%;

  .desktop-swiperslide {
    border: 1px solid grey;
    margin-bottom: 5px;
    cursor: pointer;
    height: 129px !important;

    img {
      width: 100%;
      object-fit: contain;
      height: 125px;
    }
  }
}

.mySwiperMobile {
  .swiper-slide {
    max-height: 340px;
    height: 360px;

    img {
      width: 100%;
      object-fit: contain;
      height: 100%;
    }
  }


  .swiper-pagination {
    position: relative;
    margin-top: 20px;

    .swiper-pagination-bullet-active {
      width: 25px;
      border-radius: 20px;
      background-color: $primary_color;
    }
  }
}

.desktop-swiperslide.swiper-slide-active {
  border: 2px solid $primary_color !important;
}

.previous,
.next {
  text-align: center;
}

._3AsE0T {
  max-height: 400px;
  overflow: hidden;
}

._3dtsli {
  // border: 1px solid #f0f0f0;

  ._5pFuey {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 0px 0px 5px 0px;
    font-weight: 500;
    font-size: 18px;
    font-family: $font-family-2;
    color: #212121;
  }

  ._1UhVsV {
    ._3k-BhJ {
      border-top: 1px solid #f0f0f0;
      padding: 16px 14px 7px;
      ;
      font-size: 14px;

      .flxcaE {
        padding-bottom: 16px;
        font-size: $s-size0;
        white-space: nowrap;
        font-weight: 500;
      }

      ._14cfVK {
        td {
          ul {
            list-style-type: none;
          }
        }
      }
    }
  }



}

.readmore {
  padding: 10px;
  width: 100%;
  text-align: left;
  margin-top: -1px;
  box-shadow: none;
  font-size: 16px;
  color: $primary_color;
  border: 1px solid #f0f0f0;
  font-weight: 400;
  cursor: pointer;
}

.iop {
  // margin-left: 15px;
  width: 78% !important;

  img {
    // height: 450px;
  }

}

.buttons-product-detaildiv {
  margin: unset;
  padding: unset;
}

.product-detail-buttongroup {

  // margin-left: 5%;
  .product-detail-button {
    border: none;
    border-radius: 5px;
    font-size: 15px;
    // text-transform:capitalize !important;
    // font-weight: 600;
    text-align: center;
    cursor: pointer;
    // margin-right: 15px;
    // margin-left: 15px;
    // margin-top: 2px;
    padding: 5px;
    margin: 1px 0px;
    width: 100%;

    @media screen and (max-width: 991px) {
      width: 100% !important;
      margin-left: 5px;
      // text-transform:capitalize !important;
    }
  }

  .form_submit_button_padding {
    padding: 5px !important;
  }

  .productdetails-add-to-wishlist {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
    border: 1px solid #000000;
    padding: 11px;
    margin-top: 2px;
    width: 100% !important;
  }

  .product-detail-buttonactive {
    color: $black;
    border: 1px solid black;
    background: none;
  }

  .btn-2 {
    background: none;
    // border: 1px solid $border_color;

    &:hover {
      // border: 1px solid $hover_border_color;
    }
  }
}

.product-detail-modal {
  position: fixed !important;
  top: 50%;
  left: 50%;
  width: 30em;
  margin-top: -14em;
  margin-left: -15em;
}

@media screen and (max-width: 991px) {
  .buttons-product-detaildiv {
    bottom: 0%;
    margin: unset !important;
    padding: unset !important;
    // position: fixed;
    // right: calc((100vw - 100%)/2);
    background: $white;
    // box-shadow: rgba(0, 0, 0, 0.15) 0px -1px 3px 0px;
    // z-index: 10;
  }

  .product-detail-buttongroup {
    // margin-left: 0%;
    margin: 5px;
    border-top:none !important;
  }

  .product-detail-buttongroup .product-detail-button {
    // margin-top: 4px;
    // margin-left: 10px;
    margin-right: 5px;
    // padding: 9px;

    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;

    margin-bottom: 5px;
  }
}

.my-custom-toast.Toastify__toast-container--bottom-center {
  bottom: 6em;

}

.toast-view-btn {
  font-size: $xs-size;
  background: $primary_color;
  border: none;
  border-radius: 24px;
  color: #fff;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
  padding: 4px;
  width: 30%;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast-container {
    left: 35px;
    right: 35px;
    width: 80%;
  }
}

.ss>div>img {
  max-height: 450px;
  object-fit: contain;
}

.product_detail_attributes {
  margin-top: 20px !important;
  font-size: 14px;
  // font-family: $font-family-2;
}

.product_detail_attributes_parent {
  display: flex;
  flex-direction: column;
  padding: 2px 10px 2px 10px;

}

.attributes-item {
  display: block;
  padding: 5px 0px 0px 0px;
  color: $paragraph-color;

  .attributes-item-left {
    color: #666666;

    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 22.4px;
    text-align: left;

  }

  .attributes-item-right {
    color: #084734;

    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 22.4px;
    text-align: left;


  }
}

.custom-accordion-header {
  .accordion-button {
    font-size: 14px;
    padding: 10px;
    background: #a2368b;
    position: initial;
    color: $white;
    font-weight: 500;
  }
}

.accordion-button:not(.collapsed) {
  background-color: #a2368b !important;
  color: white !important;
}

.accordion-button:focus {
  box-shadow: none !important;
  border-color: rgba(0, 0, 0, .125) !important;
}

.product_description {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #474747;

}

.product-titlebold-heading {
  padding-bottom: 20px;

  @media screen and (max-width: 991px) {
    font-size: 12px;
    padding-bottom: 10px;
  }
}

.carousel-addon-comp {
  margin-left: unset !important;
  margin-top: unset !important;
}

.carousel-addon-customnext {
  z-index: 2;
  color: $white;
}

.carousel-addon-customprevious {
  z-index: 2;
  color: $white;
  margin-left: 5px;
}

.add-ao-icon-revamp {
  margin-top: 4px;
  width: 100%;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
  border: solid 1px $primary_color;
  font-size: 12px;
  padding: 7px;
}

.delivery_slot_list {

  display: flex;
  text-align: center;
  border-right: 1px solid #bdbdbd;

  .delivery_slot {
    padding: 10px;
    width: 34%;
    border-top: 1px solid #bdbdbd;
    border-left: 1px solid #bdbdbd;
    border-bottom: 1px solid #bdbdbd;
    // border-right: 1px solid #bdbdbd;
    cursor: pointer;

    @media screen and (max-width: 991px) {
      font-size: 12px;
      padding: 6px;
    }
  }

  .activedelivery_slot {
    font-weight: 500;
    color: $primary_color;
    border-right: unset;
    border: 1px solid $primary_color ;
  }
}

.delivery_slot_time_list {
  margin-left: unset !important;
  text-align: center;

  .delivery_time_slot {
    padding: 10px;
    width: 32%;
    border: 1px solid #bdbdbd;
    margin-right: 5px;
    margin-top: 5px;
    cursor: pointer;

  }

  .activedelivery_slot {
    font-weight: 500;
    color: $primary_color;
    border-right: unset;
    border: 1px solid $primary_color ;
  }
}

.time_dropdown {
  border: 1px solid #bdbdbd;
  padding: 10px;
  cursor: pointer;
  width: fit-content;
}

.dropdown_icon {
  margin-top: -3px;
  margin-left: 5px;
}

.time-dropdown-menu {
  margin-left: 16px !important;
}

.time_dropdown {
  font-size: 12px !important;

}


.slot_custom_heading {
  color: $primary_color;
  font-weight: 500;
  margin-top: 10px;
}

.custom-time-slot {}

.addon-quantity {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;

  .addon-increment {
    cursor: pointer;
  }

  .addon-qunatity {
    font-size: 14px;
    font-weight: 500;
  }

  .addon-decrement {
    cursor: pointer;
  }
}

.active-bg-orange {
  background: $primary_color;
  color: $white;
}

.delivery_slot_parent {
  padding: 10px 20px;
}

.bundel_deals_parent {
  padding: 10px;

  .bundel_deals_products {
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    border-left: 1px solid #eee;
    border-right: 1px solid #eee;

    .bundel_deals_prod_padding {
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }

  .bundel_deals_bottom {
    border: 1px solid #eee;
    border-bottom: 1px solid #eee;

    .bundel_deals_bottom_content {
      padding: 10px;
    }
  }
}

.product-customcheckbox {
  top: 12px;
  position: absolute;
  right: 13px;
  width: 20px;
  height: 20px;
}

.custom_heading {
  padding-bottom: 10px;
  padding-top: 10px;
  font-weight: 500;
}

// .bundel_deals_bottom_content{
//   display: flex;
//   .bottom_title{
//      font-weight: 500;
//   }
//   .bottom_right_quantity{
//     color: #828282;
//   }
// }
// .bundel_deals_right{
//   float: right;
// }


.myprodcutdetailSwiper {
  margin-top: 1%;

  .desktop-myproductdetailswiperslide {
    // border: .1px solid grey;
    cursor: pointer;
    margin-bottom: 5px;

    // padding: 3px;
    img {
      // width: 100%;
      // height: 100%;
      width: 100%;
      object-fit: contain;
      opacity: .6;
      height: 96px;
      // object-fit: cover;

    }
  }

  .swiper-slide-thumb-active {

    // border: 1px solid $primary_color;
    img {
      opacity: 1;
    }

    // &:hover {
    //   opacity: 1;
    // }
  }
}

.mybigSwiper {
  img {
    max-height: 460px;
    object-fit: contain;
    width: 100%;
  }
}

.productdetail_brand {
  padding-bottom: 5px;
}

.addon_product_title_heading {
  font-weight: bold;
}

.addon_product_title_quantity {
  color: #535665;
}

.addon_selected_heading_con {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.Whishlist-productdetails {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: #F9F9F9;
  border-radius: 10px;
}

.productdetail_discount {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 47px;
  border-radius: 10px;
  font-family: Raleway;
  font-size: 14px;
  font-weight: 700;
  background: linear-gradient(270deg, #FF5790 0%, #F81140 100%);
  color: #ffffff;
  margin-right: 10px;



}

.productdetai_wishlist_share {
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-detail-btn-icon {

  font-size: 20px;
  color: linear-gradient(270deg, #FF5790 0%, #F81140 100%);

  @media screen and (max-width:991px) {
    // margin-right: 10px;
  }
}

.share-productdetails {
  width: 30px;
  height: 30px;
  background: #FFEBEB;
  display: flex;
  margin-left: 10px;
  border-radius: 50px;
  justify-content: center;
  align-items: center;

}

.share_icon {
  color: #B5A2A2;

}

.custom-cartbtn-width {
  width: 60% !important;
}

.cart-btn:hover {
  opacity: 0.9;
}

.custom-stock {
  color: $black;
  font-weight: 500;
}

.custom-variant-tooltip {
  .border-right {
    border-left: 1px solid $border_color;
    margin-left: 5px;
    padding-left: 5px;
    font-family: $font-family-2;
  }
}

input[type="checkbox"]:checked {
  border-color: red;
  background-color: red;
}

.product-detal-cart_btn .product-detail-button {
  width: 100%;
}

.detail_Specifications {
  padding-top: 10px ;
  padding-bottom: 5px;

  font-family: Raleway;
  font-size: 20px;
  font-weight: 800;
  line-height: 26px;
  text-align: left;
  color: #000000;
}

.detail-description {
  padding: 20px 0px 5px 0px;
  font-family: Raleway;
  font-size: 20px;
  font-weight: 800;
  line-height: 26px;
  text-align: left;
  color: #000000;
}

.rating_slider {

  img {
    width: 100%;
  }
}

.ratings {
  margin-bottom: 15px;
}

.ratings_user_headr {
  display: flex;
  margin-bottom: 3px;
  font-size: 13px;

  .ratings_user_title {
    margin-left: 5px;
    font-weight: 500;
  }
}

.unselected_value {
  color: lightgray;
}

.selected_value {
  color: $primary_color;
}

.ratings_container {
  margin-bottom: 15px;
}

.ratings_user_description {
  margin-top: 3px;
  font-size: 12px;
}

.rating_summary {
  margin-left: 15px;
  top: 3px;
  position: relative;
}

.ratings-header {
  color: #85481d;
  margin-bottom: 15px;
  font-size: 20px;
  font-weight: 500;
  padding-top: 0px;
  width: 100%;
}

.dimensions-productsdetails {
  padding: unset;
}

.qqqqq {
  padding: none !important;

}

.reviewtitle-heading {
  margin-left: none !important;
  margin-left: 0px !important;
}

.review-and-rating {
  margin: 0px 0px !important;
}

@media screen and (min-width:991px) {
  .review-and-rating {
    margin: 0px 20px !important;
  }
}


.heart-productdeatail {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;


  @media screen and (min-width:991px) {
    width: 100%;
  }
}

.eightcardbanner {
  width: 100%;
  object-fit: contain;
  margin: 10px 0px;

}

.eightbanner-images {
  margin: 0px 3px 0px 1px !important;
}

.cart-quentity-div {
  margin-right: 0px !important;
  padding-right: 0px !important;
}

// .......................................addons
.Addon-names {
  display: flex;
  justify-content: flex-start;
  flex-direction: row !important;


}

.Addons-title {
  border: 1px solid #eee;
  margin-right: 5px !important;
  border-radius: 5px;
  text-align: center;
  width: -moz-fit-content;
  width: fit-content;
  padding: 5px 10px 5px 10px;
  margin-bottom: 5px;
}

.addon-before {
  border: 1px solid lightgrey;
}

.Addon-main-title {
  margin: 10px 0px;
  padding-bottom: 10px;
}

.Addon-images {
  display: flex;
  flex-direction: row;

}

.Addon-image-div {
  margin: 0px 10px 10px 0px;
  width: 90px;
  // height: 90px;
  display: flex;
  justify-content: center;
  border: 1px solid lightgray;
}

// .Addon-image-div:hover{
//   border: 1px solid black;
// }
.image-addon {
  width: 100%;
  cursor: pointer;
}

.imageborder {
  border: 2px solid black;
}

// ...................................bundles
.bundle-top {
  border-top: 1px solid #dedede;
  padding-top: 15px;
  cursor: pointer;
}

.bundle-products {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.bunder-outer {
  border: 1px solid lightgray;
  margin: 10px 0px;
  display: flex;
  align-items: center;
  padding: 10px;
}

.quantityContainer1 {
  display: flex;
  justify-content: space-evenly;
  border: 1px solid lightgray;
  border-radius: 5px;
  align-items: center;
}

.bundlecheckbox {
  zoom: 1.6;
}

.bundle-checkbox-main {
  margin: 0px 10px;
}

// .Product-selected {
//   // border: 1px solid blue;
// }
.plusButton1 {
  cursor: pointer;
}
.active-circle{
  background: red;
}
.deliverystatus_productdetail{
  background: #FFBE12;
padding: 4px 8px;
font-size: 14px;
color: #333333;

font-family: Inter;
width: fit-content;
border-radius: 50px;
font-size: 12.67px;
font-style: italic;
font-weight: 600;
line-height: 13.93px;
text-align: left;


}
.deliverystatus_productdetaildate{
  font-family: Inter;
font-size: 14px;
font-weight: 500;
line-height: 18.2px;
text-align: center;
display: flex;
align-items: end;
color: #084734;
padding-left: 10px;

}
.dtailpage_addtocartbtn{
  padding: 15px !important;
}
.productdetailcurrencysymbol{
  font-size: 12px !important;
  line-height: 23px;
}
.productactual-price{
  display: flex;
}
.paymenttotalcurrency_symbol{
  font-size: 10px !important;
  line-height: 23px;
}