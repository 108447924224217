.register-container {
    margin: auto;
    width: 50%;
    border: 1px #ddd solid;
    margin-top: 50px;
    position: relative;
    background: var(--Dark-Blue---Deals-24x7, #192253);
    color: #fff !important;

    .register-wrap-container {
        justify-content: center;
        border-radius: 5px;
        position: absolute;
        top: 0px;
        left: 0px;

        color: #fff !important;
        background: var(--Dark-Blue---Deals-24x7, #192253);
        // background: var(--Dark-Blue---Deals-24x7, #192253);

        -webkit-box-shadow: 0px 10px 34px -15px rgba(0, 0, 0, 0.24);
        -moz-box-shadow: 0px 10px 34px -15px rgba(0, 0, 0, 0.24);
        box-shadow: 0px 10px 34px -15px rgba(0, 0, 0, 0.24);

        .left-side {
            display: flex;
            align-items: center;
            text-align: center;
            background-image: linear-gradient(rgba(0, 0, 0, 0.5),
                    rgba(0, 0, 0, 0.5)), url("https://cdn-site.people.ai/2022/06/07100746/10-top-b2b-sales-strategies-for-2022-1170x780-1.png");
            background-size: cover;
            background-position: center;

            .text {
                color: #fff;

                .signin-btn {
                    border: 2px solid $primary_color;
                    border-radius: 24px;
                    color: #fff;
                    font-size: 16px;
                    font-weight: 700;
                    margin: auto;
                    text-align: center;
                    cursor: pointer;
                    padding: 4px;
                    width: 30%;
                }
            }
        }

        .right-side {
            // background: #fff;
            img {
                max-height: 150px;
                object-fit: contain;
            }
            h4 {
                // font-weight: 700;
            }
            h4 span {
                font-size: 16px;
                display: block;
                margin-top: 10px;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.4);
            }
            .signupsec {
                margin-top: 2px;
                text-align: center;
                .DontAccount {
                    // color: #828282;
                    // font-size: 12px;
                    // font-weight: 400;
                }
                .signUpNavLink {
                    // color: $primary_color;
                    // font-family: Poppins, sans-serif;
                    // font-size: 12px;
                    // font-weight: 600;
                    margin-top: 20px;
                    margin-left: 5px;
                    text-decoration: none;
                }
            }

            .navlink-2 {
                margin-top: 0px;
            }
        }
    }
}

.fomdataaa {
    // position: absolute;
    z-index: 10;
    width: 100%;
}

.loginregister {
    justify-content: center;
    padding: 2rem;
    // position: absolute;
    z-index: 100 !important;
    // top: 0px;
    // margin-top: 100px;
    background-color: none !important;
    background-color: unset !important;
    // left: 0px;
}

.loginbackbtn {
    font-family: Raleway;
    font-size: 16px;
    font-weight: 600;
    line-height: 17.6px;
    text-align: left;
    padding-left: 5px;

}

.loginbackbtnicon {
    font-family: Raleway;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    line-height: 17.6px;
    text-align: left;
    cursor: pointer;

}

.login_text {
    font-size: 35px !important;
    font-family: Raleway;

    font-weight: 700;
    line-height: 61.05px;
    letter-spacing: -0.5199999809265137px;
    text-align: left;
    margin-top: 10px;
}

.bubble2imag {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 10;
}


.bubble1imag {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;
}

.bubble3imag {
    position: absolute;
    top: 35px;
    right: 0px;
    z-index: 1;
}

.marginleft {
    margin-left: 5px;
}

.login-container {
    margin: auto;
    width: 33%;
    border: 1px #ddd solid;
    margin-top: 50px;
}

.login-register-btn {
    width: 100%;
    margin-left: unset;

    .product-detail-button {
        // margin-top: 15px !important;
        margin-right: unset;
    }
}

.normal-register-btn {
    justify-content: center;

    .product-detail-button {
        margin-top: 15px !important;
        // width: 50%;
    }
}

@media only screen and (max-width: 991px) {
    .register-container {
        width: auto;
        margin: 20px;

        .product-detail-button {
            width: 100% !important;
            margin-left: unset;
        }
    }

    .left-side {
        padding: 1rem !important;
    }

    .right-side {
        padding: 1rem !important;
        border: none !important;
    }
}

.disable {
    pointer-events: none;
}

.captcha-div {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.formsdata_container {
    position: relative;
    // background: var(--Dark-Blue---Deals-24x7, #192253);
    // width: 340px !important;
    // background: #f8f9fa;
    border-radius: 5px;
    box-shadow: 0 10px 34px -15px #0000003d;
    display: flex;
    // justify-content: center;
    // align-items: center;
    @media only screen and (max-width: 600px) {
        width: 100%;
    }
}

.form_main_container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px 0px !important;
}

.loginscreen_goodtosee {
    font-family: Nunito Sans;
    font-size: 14px;
    font-weight: 300;
    line-height: 35px;
    text-align: left;

}

.login-width {
    @media only screen and (min-width: 991px) {
        width: 350px;
    }
}

.loginheart {

    font-size: 15.6;

}

.loginscreen_forgotpassword {
    font-family: Nunito Sans;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    text-align: start;
    width: fit-content;
    border-bottom: 1px solid white;

}

.linkwithggogle-apple {
    border: 1px solid #ddd;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px 0px;
    cursor: pointer;
    font-family: Nunito Sans;
    font-weight: 600;
    color: #3c4043;
    padding: 7px 0px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    position: relative;

}

.linkwithggogle-appleicon {
    position: absolute;
    top: 5px;
    left: 20px;
    font-size: 18px;

}

.login-register {
    font-family: Raleway;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
}

.forgotpassword_width {

    @media only screen and (min-width: 991px) {
        max-width: 400px;
    }
}

.cancelbtn_instructionform{
    font-size: 14px;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #ddd;
    text-align: center;
    width: 50%;
    margin-left: 2px;
}
.Submitbtn_instructionform{
    font-size: 14px;
    text-align: center;
    background: #c23a97;
    color: #fff;
    border: none !important;
    padding: 5px;
    border-radius: 5px;
    width: 50%;
}
.facebook{
    border: none !important;
    background: none !important;
}
.custom-google-login-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #4285f4; /* Google's blue color */
    color: white;
    font-size: 16px;
    font-weight: bold;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.custom-google-login-btn:hover {
    background-color: #3367d6; /* Darker blue */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.custom-google-login-btn:disabled {
    background-color: #d3d3d3;
    cursor: not-allowed;
}
.google-login-container {
    text-align: center;
    margin-top: 20px;
}

.custom-google-login-btn {
    background-color: #4285F4 !important; /* Google's blue color */
    color: white !important;
    border: none !important;
    border-radius: 4px !important;
    padding: 10px 20px !important;
    font-size: 16px !important;
    cursor: pointer !important;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2) !important;
    transition: background-color 0.3s ease !important;
}

.custom-google-login-btn:hover {
    background-color: #357ae8 !important;
}

.custom-google-login-btn:disabled {
    background-color: #c1c1c1 !important;
    cursor: not-allowed;
}

.google-login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.custom-google-login-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #4285f4; /* Google's blue color */
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease;
}

.custom-google-login-btn:hover {
    background-color: #357ae8;
}

.custom-google-login-btn:disabled {
    background-color: #c1c1c1;
    cursor: not-allowed;
}

.google-logo {
    height: 20px;
    margin-right: 10px;
}
.progressbaritem{
    height: 100px;
    width: 95% !important;
}