// 
// COLORS ---------
// 

:root {
  --nav-bg: #f8f8f8;
  --nav-bg-scrolled: #fff; 
  --nav-bg-shadow: 0 5px 10px rgba(0, 0, 0, .02);
}

// one basket
// $header-bg : #fff;
// $header-text-color : #000;
// $nav-bg: #85481d;
// $nav-text-color: white;
// $primary_color:#85481d;
// $app-aspect-ratio:1/1;

// kk toys
// $header-bg : #fff;
// $header-text-color : #000;
// $primary_color:#B21217;
// $nav-bg: #232f3e;
// $nav-text-color: white;
// $app-aspect-ratio:1;

// jhaluck
$header-bg : #fff;
$header-text-color : #000;
$primary_color:#000;
$nav-bg: #19212b;
$nav-text-color: white;
$app-aspect-ratio:.8;

// apnashopee
// $header-bg : #fff;
// $header-text-color : #000;
// $primary_color:#ef8121;
// $nav-bg: #102f54;
// $nav-text-color: white;
// $app-aspect-ratio:.8;









// General
$white: #fff;
$black: #000;
$gray-10: #818181;
$gray-20: #ccc;
$secondary_color:#535665;

$paragraph-color:#535665;

$border_color:#d4d5d9;

$hover_border_color:#000;