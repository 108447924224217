.mycategorySwiper {
    .swiper-wrapper{
        // justify-content: center ;
    }
    .categorySwiper-swiperslide {
        //   border: 1px solid grey;
        margin-bottom: 5px;
        cursor: pointer;
        border-radius: 5px;
        text-align: center;
        margin-left: 3px;
        margin-right: 3px;

        //   height: 129px !important;
        .allcategory-title {
            text-align: center;
            word-break: break-all;
            
                // display: flex;
                // font-size: 12px;
                // font-weight: 500;
                margin: 5px 0px ;
                // justify-content: center;
                // font-family: $font-family-2;
                @media only screen and (max-width: 576px) {
                    margin: 0px 0px 0px 0px;
                }
        }

        img {
            // width: 50px;
            // height: 50px;
            @media only screen and (max-width: 576px) {
                width: 40px;
                height: 40px;
            }
            // border-radius: 15px;
            object-fit: cover;
        }
    }
}
h6{
    line-height: 1.0 !important;
}
h5{
    margin-bottom: 0px !important;
}

