.home-component4 {
    background: $white;
    border-radius: 12px;
    padding: 15px;
    position: relative;
    .home-component4-carousel{
        // transition-duration: 3000ms;
        // transition-property: opacity;
        // transition: opacity 0s ease;
        @media only screen and (min-width: 991px) {
            height: 450px;
        }
        img{
            // width: 100%;
        }
    }
}
.eight-card-comp {
    transition: opacity 0s ease !important; 
}

.eight-card-item {
    opacity: 0;
    transition: opacity 0s ease !important; 
}