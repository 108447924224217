// 
// UTILS ---------
//

.d-block {
  display: block;
}

.d-flex {
  display: flex;
}

.justify-space-between {
  justify-content: space-between;
}

.custom-disabled {
  pointer-events: none;
  opacity: .5;
}


.z-index-1 {
  z-index: -1;
}

.margin-bottom-xs {
  margin-bottom: $xs-size;
}

.m-b-75 {
  @media only screen and (max-width: 991px) {
    margin-bottom: 75px;
  }

  margin-bottom: 10px;
}

.m-b-45 {
  @media only screen and (max-width: 991px) {
    margin-bottom: 45px;
  }

  margin-bottom: 10px;
}

.main-backdrop {
  // position: fixed;
  // opacity: 1;
  // z-index: 80;
  // width: 100vw;
  // height: 100%;
  // background-color: rgba(0, 0, 0, .8);
  // display: block;
  // left: 400px;
}

.main-backdrop-extra {
  left: 0px;
}

.divider {
  width: 100%;
  height: 3px;
  background: #f1f1f1;
  margin-bottom: 5px;
}

.color-black {
  color: #4f4f4f;
}

.overflow-hidden {
  overflow: hidden;
}

.text-decoration-none {
  text-decoration: none;
  color: unset;
}

.text-decoration-none1 {
  text-decoration: none;
  color: $primary_color;
}

.custom-border-bottom {
  border-bottom: 1px solid #eee;
}

.custom-border-top {
  border-bottom: 1px solid #eee;
}

.border-top {
  border-top: 1px solid #e0e0e0;
}

.border-bottom {
  border-top: 1px solid #e0e0e0;
}

.custom-border-grey {
  border: 1px solid #eee;
}

.padding-bottom {
  padding-bottom: 10px;
}

.custom-height {
  height: 100vh;
}

.border-none {
  border: none !important;
}

.padding-right-unset {
  padding-right: unset !important;
  margin: 20px 0px;
  padding: 20px 0px !important;
  align-items: center;
}

.color-primary_color {
  // color: $primary_color;
}

.padding-left-unset {
  padding-left: unset !important;
}

.space-5 {
  padding-top: 5px;
}

.space-10 {
  padding-top: 10px;
}

.space-15 {
  padding-top: 15px;
}

.space-20 {
  padding-top: 20px;
}

// .space-30{
//   padding-top: 30px;
// }

.home-custom-container {
  max-width: 1600px !important;
  //  padding: none !important;
  padding: 0px !important;

  @media only screen and (max-width: 576px) {
    max-width: unset !important;
    padding-left: unset !important;
    padding-right: unset !important;
    overflow-x: hidden;
  }
}

.overlay-b-t-dark {
  background: linear-gradient(-180deg, rgba(234, 237, 237, 0), #E3E6E6);
  // background: rgba(0,0,0,0.5);
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 60%;
  width: 100%;
}