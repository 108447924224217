.about-main{
    position: relative; 
    // font-family: $font-family-2;
    .about-content{
        position: absolute;
        background: #fff;
        top:80px;
         
        width: 30%;
        right: 5%;
        padding: 20px;
        line-height: 2.2;
    }

    .about-button{
        background:$primary_color;
        color: #fff;
        padding: 10px 40px;
        width: fit-content;
        font-size: 18px;
        margin-top: 10px;
    }
}
@media screen and (max-width: 991px){
    .about-main{
        position: unset; 
        // font-family: $font-family-2;
        .about-content{
            position: unset;
            background: #fff;
            top:none;
             
            width: 100%;
            right: none;
            padding: 10px;
            line-height:unset;
        }
    
        .about-button{
            background:$primary_color;
            color: #fff;
            padding: 10px 40px;
            width: fit-content;
            font-size: 18px;

            margin: 10px 0px 30px 0px;
        }
    }
}

// .map-area{
//     overflow: hidden;
//     position: relative;
//     padding-top: 10px;
// }
// .map-area{
//     position: absolute;
//     border: 0;
//     height: 100%;
//     // width: 100%;
//     left: 0;
//     top: 0;
// }

// .map-area {
//     overflow: hidden;
//     position: relative;
//     padding-top: 10px;
// }

// /* Style for the child iframe with the same .map-area class */
// .map-area iframe {
//     position: absolute;
//     border: 0;
//     height: 100%;
//     /* width: 100%; */
//     left: 0;
//     top: 0;
// }

.address-map{
    width: 100%;
    height: 600px;
}

@media screen and (max-width:576px){
    .address-map{
        width: 100%;
        height: 300px;
    }
}



// ................................BANNER8............................

.brands-col-maindivs {
    width: 100%;

    padding: 10px 10px !important;

    .single-banners {
        position: relative;
        cursor: pointer;
        width: 100%;
        .brand-imagess {
            width: 100%;
            object-fit: cover;
        }
    }

}
.brand-title{
    font-size: 22px;
    // font-family: Roboto;
    font-weight: 500;
    display: flex;
    justify-content: center;
    padding-top: 5px;
}
.brand32-main {
    display: flex;
}

.dfdbhgh {
    border: 1px solid black;
}


.single-banners::before {
    border-top: 2px solid #fff;
    // border-bottom: 2px solid #fff;
    // border-right: 2px solid #fff;

    content: "";
    left: 15px;
    opacity: 0;
    right: 100%;
    position: absolute;
    top: 15px;
    // -webkit-transition: all 600ms ease 0s;
    // transition: all 600ms ease 0s;
    // z-index: 1;
}

.single-banners::before {
    // border-top: 2px solid #fff;
    border-bottom: 2px solid #fff;
    // border-right: 2px solid #fff;

    content: "";
    left: 15px;
    opacity: 0;
    right: 100%;
    position: absolute;
    bottom: 18px;
    -webkit-transition: all 600ms ease 0s;
    transition: all 600ms ease 0s;
    // z-index: 1;
}

.single-banners:hover:before {
    opacity: 1;
    right: 15px;
}

.single-banners::after {
    border-right: 2px solid #fff;
    bottom: 19px;
    content: "";
    left: 15px;
    opacity: 0;
    position: absolute;
    // top: 15px;
    -webkit-transition: all 600ms ease 0s;
    transition: all 600ms ease 0s;
    -webkit-transition: all 0.5s ease-out;
}

.single-banners::after {
    border-left: 2px solid #fff;

    content: "";
    right: 15px;
    opacity: 0;
    position: absolute;
    top: 100%;
    -webkit-transition: all 600ms ease 0s;
    transition: all 600ms ease 0s;
    // -webkit-transition: all 0.5s ease-out;
}

.single-banners:hover:after {
    opacity: 1;
    top: 15px;
}

.special-collection{
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 28px;
    // font-family: $font-family-2;
    font-weight: 600;
}

// ...............................................imagecard


.imagecard-bannres{
    position: relative;
   }
   .imagecard-mainimage{
       width: 100%;
       // height: 400px;
       object-fit: cover;
       @media screen and (max-width:991px){
           height: 400px;
       }
   }
   .imagecard-rowbanner{
       position: absolute;
       width: 100%;
       height: 100%;
   
   }
   .imagecard-subcard{
       background: linear-gradient(140deg, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.4) 100%);;
       margin: 60px 40px !important;
       display: flex;
       justify-content: center;
       align-items: center;
       text-align: center;
       @media screen and (max-width:991px){
           margin: 20px 10px !important; 
       }
   }
   
   .about-title{
       font-size: 40px;
       font-weight: 700;
    //    font-family: $font-family-2;
       // @media screen and (max-width:991px){
       //     font-size: 40px;
       // }
   }
   .imagecard-main-subcontent{
       display: flex;
       justify-content: center;
       flex-direction: column;
       align-items: center;
       text-align: center;
   
       width: 100%;
       height: 80%;
       margin: 20px !important;
       border: 1px solid $primary_color;
       position: relative;
   }
   .classiccollection-topright{
       position: absolute;
       top: 0;
       right: 0;
   }
   .classiccollection-topleft{
       position: absolute;
       top: 0;
       left: 0; 
   }
   .classiccollection-bottomleft{
       position: absolute;
       bottom: 0;
       left: 0; 
   }
   .classiccollection-bottomright{
       position: absolute;
       bottom: 0;
       right: 0; 
   }


//    .............................selected category




.ImageCard-main-div {

    // width: 100%;
  
    // border-radius: 50%;
    // background: #FFF;
    -webkit-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
    padding: 15px;
    // max-width: 375px;
    // margin: 0 auto;
    // height: 100%;
}


.Singleimage-image {
    display: block;
	width: 100%;
    height:auto;
	// border-radius:5px;
	-webkit-transition: all .2s ease-out;
	-o-transition: all .2s ease-out;
	transition: all .2s ease-out;
}

  .images-hover-sub-div:hover .Singleimage-main-image-div .Singleimage-image, .images-hover:hover .Singleimage-main-image-div .Singleimage-image{
    -webkit-transform: scale(1.2);

	-moz-transform: scale(1.2);
	-ms-transform: scale(1.2);
	-o-transform: scale(1.2);
	transform: scale(1.2);
	-webkit-transform: scale(1.2) rotate(4deg);
    transform: scale(1.2) rotate(4deg);
    -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=31.01)";
    filter: alpha(opacity=30.01);
	-webkit-transition: all .2s ease-out;
	-o-transition: all .2s ease-out;
	transition: all .2s ease-out;
}
.Singleimage-maindiv {
    display: flex;
    font-size: 14px;

    .Singleimage-author {
        padding: 5px 0px;

    }

    .Singleimage-1 {
        margin-right: 10px;
    }
   
    .Singleimage-author-icon {
        color:$primary_color;
        background: $gray-20;
        padding: 2px;
        margin-right: 5px;
        border-radius: 2px;
        font-size: 15px;
        align-items: center;
    }
    .icondata{
        font-size: 12px;
    }
}
.image-animation-main{
    overflow: hidden;
    border-radius: 50%;
}
.animation-maincol{
    padding: 15px !important;
}
.animations-div{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

}

// .............................................threebannerCategory



.brands-col-maindivs {
    width: 100%;

    padding: 10px 10px !important;

    .single-banners {
        position: relative;
        cursor: pointer;
        width: 100%;
        .brand-imagess {
            width: 100%;
            object-fit: cover;
        }
    }

}
.brand-title{
    // font-size: 22px;
    // font-family: Roboto;
    // font-weight: 400;
    display: flex;
    justify-content: center;
    padding-top: 10px;
}
.brand32-main {
    display: flex;
}

.dfdbhgh {
    border: 1px solid black;
}


.single-banners::before {
    border-top: 1px solid #fff;
    // border-bottom: 2px solid #fff;
    // border-right: 2px solid #fff;

    content: "";
    left: 15px;
    opacity: 0;
    right: 100%;
    position: absolute;
    top: 15px;
    // -webkit-transition: all 600ms ease 0s;
    // transition: all 600ms ease 0s;
    // z-index: 1;
}

.single-banners::before {
    // border-top: 2px solid #fff;
    border-bottom: 1px solid #fff;
    // border-right: 2px solid #fff;

    content: "";
    left: 15px;
    opacity: 0;
    right: 100%;
    position: absolute;
    bottom: 18px;
    -webkit-transition: all 600ms ease 0s;
    transition: all 600ms ease 0s;
    // z-index: 1;
}

.single-banners:hover:before {
    opacity: 1;
    right: 15px;
}

.single-banners::after {
    border-right: 1px solid #fff;
    bottom: 19px;
    content: "";
    left: 15px;
    opacity: 0;
    position: absolute;
    // top: 15px;
    -webkit-transition: all 600ms ease 0s;
    transition: all 600ms ease 0s;
    -webkit-transition: all 0.5s ease-out;
}

.single-banners::after {
    border-left: 1px solid #fff;

    content: "";
    right: 15px;
    opacity: 0;
    position: absolute;
    top: 100%;
    -webkit-transition: all 600ms ease 0s;
    transition: all 600ms ease 0s;
    // -webkit-transition: all 0.5s ease-out;
}

.single-banners:hover:after {
    opacity: 1;
    top: 15px;
}
.new-addition{
    display: flex;
    justify-content: center;
    text-align: center;
    // font-family: $font-family-2;
    // font-weight: 500;
    padding-bottom: 10px;
}
.special-collection{
    display: flex;
    justify-content: center;
    text-align: center;
    // font-size: 40px;
    // font-family: $font-family-2;
    // font-weight: 500;
    padding-bottom: 30px;
}
.imageanimation-title{
    // font-size: 24px;
    // font-weight: 400;
    // font-family: $font-family-2;
}





















