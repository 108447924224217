.orderDetails{
    border: 1px solid #dbdbdb;
    border-radius: 5px;
    display: flex;
    justify-content: left;
    padding-bottom: 20px;
    
    .orderDetailsHead{
        padding-top: 10px ;
       
    }
 
    .orderDetailsCol1{
       
        display: grid;
        
        .orderDetailsemail{
            padding: 0px 2px 10px 0px;
            display: flex;
            font-size: 13px;
            
        }
        .information{
            font-weight: 800;
            padding-bottom: 5px;
            padding-top: 10px;
        }
        .add{
            font-size: 13px;
        }
        .deliveryDetails{
            font-size: 13px;
        }
    }
    .orderDetailsCol2{
        .information{
            padding-top: 10px;
            padding-bottom: 5px;
            color: black;
           
        }
        .orderDetailsvisa{
            padding: 0px 2px 10px 0px;
            display: flex;
            font-size: 13px;
            img{
                width: 30px;
                margin-right: 10px;
            }
        }
        .add{
            font-size: 13px;
        }
        
    }
}
.nsm7Bb-HzV7m-LgbsSe{
    // border-radius: 10px !important;
    // border: 1px solid green !important;
 }
 .nsm7Bb-HzV7m-LgbsSe{
    
 }
 .bottomdeliveryaddresname{
    font-size: 12px;
    font-weight: 700;
    padding-bottom: 5px;
    
 }
 .bottonfull_address{
    padding-bottom: 15px;
 }
 .bottomaddressname{
    font-size: 12px;
    font-weight: 500;
 }
 .bottomdeliveryaddress{
    font-size: 12px;
    font-weight: 400;
 }
 .orderbottonright_main{
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    font-weight: 400;
 }
 .orderbottonright_main_total{
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    font-weight: 700;
 }
 .bottom_details{
    border-top: 1px solid #d4d5d9;
    padding: 10px;
    margin: 0px 18px;
 }
 .order-coloum{
    padding: 10px !important;
 }