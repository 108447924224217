.productdetail-bankoffers-main {
    background: #C23A971A;
    margin: 20px 0px !important;
    padding: 20px 30px !important;
    border-radius: 50px 0px 0px 50px;
}

.product_detailBankoffers {

    margin: 20px 0px;

}

.bankoffer_image {
    width: 60.76px;
    height: 16px;


}

.bankoffers_title {
    font-family: Raleway;
    font-size: 20px;
    font-weight: 800;
    line-height: 21px;
    letter-spacing: -0.17000000178813934px;
    text-align: left;

}

.bankoffers_content {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 5PX 0PX;
}

.bankoffers_tagicon {
    font-size: 20px;
    margin-right: 10PX;
    color: #3C3271;
}

.bankoffers_offers {
    font-family: Nunito Sans;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;



}

.bankoffers-Price {
    color: #F81140 !important;
    font-family: Nunito Sans;
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    text-align: left;

}

.bankoffers_seelall {
    font-family: Raleway;
    font-size: 15px;
    font-weight: 700;
    line-height: 17.61px;
    text-align: left;
    color: #202020;

}

.Bankoffers_arrowicon {
    margin-left: 10px;
    color: #3C3271;

}


//..............................................detailpage delivery
.detaildelevery {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    flex-direction: row;
    border: 1px solid black;
    padding: 10px 20px;
    border-radius: 5px;
    margin-top: 10px;
}

.delivery_title {
    font-family: Raleway;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: #000000;

}

.detailpage_deliveryleft {
    display: flex;
    flex-direction: row;

    justify-content: center;
    align-items: center;
    text-align: center;
}

.delivery_days {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    background: #F5F8FF;
    width: 72px;
    height: 26px;

    border-radius: 4px;
    font-family: Raleway;
    font-size: 13px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: -0.12999999523162842px;
    text-align: center;



}

.deliverystatus {
    font-family: Raleway;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: -0.1599999964237213px;
    text-align: right;
    color: #000000;

}