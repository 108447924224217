.mobile-filters-header {
  position: fixed;
  top: 113px;
  background: rgb(255, 255, 255);
  width: 100%;
  padding: 10px 0px 0px 0px;
  z-index: 11;
  transition: top 0.6s ease 0s;


  .sort-filter-options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    padding: 0px;
    margin-bottom: 5px;
    padding-right: 30px;
    height: 29px;
    background: rgb(255, 255, 255);

    .__sort-filter-options_item {
      cursor: pointer;
      // width: 100%;
      height: 100%;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
      position: relative;
      overflow: hidden;
      // padding: 4px;
      padding: 8px;
      border: 1px solid #e0e0e0;

      .content {
        padding-left: 4px;
        color: rgb(53, 53, 67);
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        margin: 0px;
        padding: 0px;
      }
    }
  }
}

.custom-filter-modalsheet {
  height: auto !important;
  border-top-left-radius: 25px !important;
  border-top-right-radius: 25px !important;

  .custom-filter-sheet-haeder {
    padding: 15px 15px 10px 15px;
    display: flex;
    font-size: $s-size;
    border-bottom: 1px solid #eee;
    justify-content: space-between;
  }

  .filter-body {
    display: flex;
    height: calc(90vh - 122px);
    background-color: rgb(255, 255, 255);
    border-radius: 0px;

    .filter-body-leftside {
      flex: 1 1 50%;
      overflow-y: scroll;
      background: rgb(234, 234, 242);

      .fOzBOt {
        width: 100%;
        position: relative;
        padding: 16px 12px 16px 7px;
        color: rgb(97, 97, 115);
        background: rgb(234, 234, 242);
        font-size: 13px;
        border-bottom: 1px solid rgb(206, 206, 222);
      }

      .active-category {
        background: rgb(255, 255, 255);
        color: $primary_color;
        font-weight: bold;
        border-left: 4px solid $primary_color;
      }
    }

    .filter-body-rightside {
      flex: 1 1 66%;
      overflow-x: scroll;
      margin: 14px
    }
  }
}

.mobile-filter-apply-btn {
  .product-detail-button {
    font-size: $s-size;
    padding: 5px;
  }

}

.sort-title {
  font-size: 16px;
  font-weight: 600;
}

.mobileFilter-shortBy {
  overflow-y: scroll;
  margin-bottom: 10px;

  .mobileFilter-shortBy-list {
    padding: 6px 10px;

    .mobileFilter-shortBy-list-radio {

      border-width: 3rem;
    }

    .mobileFilter-shortBy-list-content {
      padding-left: 10px;
      font-size: 16px;
    }
  }
}

.double-slider-box {
  background-color: #fff;
  border-radius: 10px;
  width: 80%;
  padding: 20px 0px;
  margin: auto;
}

.range-slider {
  position: relative;
  width: 90%;
  height: 5px;
  margin: 30px 0;
  background-color: #e6d7d7;
  border-radius: 5px;
}

.slider-track {
  height: 100%;
  position: absolute;
  background-color: #70b6cc;
  left: 0;
  right: 100%;
  border-radius: 5px;
}

.range-slider input[type="range"] {
  position: absolute;
  width: 100%;
  top: 3px;
  transform: translateY(-50%);
  background: none;
  pointer-events: none;
  appearance: none;
  height: 5px;
}

input[type="range"]::-webkit-slider-thumb {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  // border: 3px solid #fff;
  background: #70b6cc;
  pointer-events: auto;
  appearance: none;
  cursor: pointer;
  box-shadow: 0 0.125rem 0.5625rem -0.125rem rgba(0, 0, 0, 0.25);
  position: relative;
  z-index: 2;
  /* Ensure thumbs appear above the track */
}

input[type="range"]::-moz-range-thumb {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  border: 3px solid #fff;
  background: #fe696a;
  pointer-events: auto;
  cursor: pointer;
  box-shadow: 0 0.125rem 0.5625rem -0.125rem rgba(0, 0, 0, 0.25);
  position: relative;
  z-index: 2;
}

.input-box {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.min-box,
.max-box {
  width: 50%;
}

.min-box {
  margin-right: 10px;
}

.max-box input {
  float: right;
}

// input[type="number"] {
//   width: 40px;
//   padding: 10px;
//   border: 1px solid #ccc;
//   border-radius: 5px;
//   text-align: center;
// }

.min-tooltip,
.max-tooltip {
  position: absolute;
  top: -35px;
  font-size: 12px;
  width: fit-content;
  color: #555;
  background-color: #fff;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 5px;
  white-space: nowrap;
  z-index: 1;
}

.min-tooltip {
  left: 0;
  transform: translateX(-50%);
}

.max-tooltip {
  right: 0;
  transform: translateX(50%);
}

/* Chrome, Safari, Edge, and Opera */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.max-tooltip {
  border: none !important;
  padding: 0px !important;
}

.min-tooltip {
  border: none !important;
  padding: 0px !important;
}

.filtercheckoutmobile {
  height: 100vh;
  border-top-right-radius: 0px !important;
  border-top-left-radius: 0px !important;
  overflow-y: scroll;
  padding-top: 53px !important;

}

.filtermobiletop {
  display: flex;
  margin: 10px;
  justify-content: space-between;

}

.filtermobiletop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  // position: fixed;
  // width: 100%;
  // top: -11px;
  background: #fff;
  z-index: 10;
}

.filter_title {
  font-size: 16px;
  font-weight: bold;
}

.filterCategory {
  margin-bottom: 20px;
}

.filterHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.filterFooterButtons {
  display: flex;
  justify-content: center;
  flex-direction: row;
  padding-top: 5px;
}

.filterApplyButton {
  font-family: Nunito Sans;


  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 5px;
  padding: 10px;
  margin-right: 5px;
  text-align: center;
  width: 70%;
  border-radius: 5px;
}

.filterClearButton {
  border: 1px solid #192253;
  padding: 10px;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 5px;
  margin-right: 5px;
  margin-left: 5px;
  padding: 10px;
  margin-right: 5px;
  text-align: center;
  border-radius: 5px;
}

.filtermainsymbol {
  padding: 5px;
  border-radius: 5px;
}

.filtersymbol {
  font-size: 18px;



  color: #fff;
}

.filtercartsymbol {
  font-size: 25px;
  color: #000;
}

.Resulttitle {
  font-size: 16px;
  font-weight: 700;
}