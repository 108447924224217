.header-main {}

.Main_header {
  width: 100%;
  height: 115px;
  // background: linear-gradient(90deg, #C23A97 0%, #192253 100%);
  position: sticky;
  top: 0;
  z-index: 999;
  // color: #fff;
}

.header {
  width: 100%;
  height: 75px;
  padding: 0px 20px;
  // background: $header-bg;
  display: flex;
  align-items: center;
  // box-shadow: none;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 10;
  // background: linear-gradient(90deg, #C23A97 0%, #192253 100%) !important;
  background: none !important;
  // color: white;

  .logo img {
    object-fit: contain;
    width: 240px;
    height: 56.9px;
  }

  .logo a {
    display: block;
    height: 100%;
  }

  // .searchbar {
  //   width: 650px;
  //   margin-left: 25px;
  // }



  .header-item-right {
    // width: 174px;
    // height: 48px;
    justify-content: center;
    display: flex;
    position: relative;

    .Profile-Dropdown {
      display: flex;
      padding: 3px;

      .Profile-Dropdown-icon {
        padding-right: 10px;
        // color: $primary_color;
        display: flex;
        align-items: center;
        text-align: center;
      }

      .Profile-Dropdown-name {
        font-size: 14px;
      }
    }

    .Profile-Dropdown-icon-arrow {
      position: absolute;
      top: -23px;
      font-size: 23px;
      left: 65%;
      color: white;
    }
  }



  .header-item-left {
    display: flex;
  }

  .header-address {
    display: flex;
    margin-left: 20px;
    margin-top: 17px;
    cursor: pointer;

    .location-marker {
      margin-top: 13px;
      margin-right: 4px;
    }

    .header-address-line1 {
      font-size: $xs-size;
    }

    .header-address-line2 {
      font-size: $s-size;
      font-weight: 400;
    }
  }

  .dropdown-up-title {
    // font-size: $xs-size;
  }

  #nav-dropdown-dark-example {
    // font-size: $s-size;
  }

  #nav-dropdown-search {
    color: $header-bg;
    font-size: $s-size;
  }


  .headersignup {
    font-size: 12px;
  }

  .header_signup {
    padding: 5px 10px;
    border-radius: 5px;
  }

  .input-group-text {
    border: none;
  }

  .cart {
    margin-left: 10px;
    margin-top: 8px;

    .custom-badge {
      // background: $primary_color;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0px 5px;
      position: absolute;

      width: 24px;
      height: 24px;
      border: 3px 0px 0px 0px;

      // background: #33B36B;
      margin: -34px 0px 0px 20px;
    }
  }
}

.Header_whishlist {
  width: 30px;
  height: 26px;
  color: #FFFFFF;
}

.Header_profileicon {
  width: 26px;
  height: 30px;
  color: #FFFFFF;
}

.Header_cart {
  // width: 30px;
  // height: 30px;
  // color: #FFFFFF;

}

.dropdown-menu {
  --bs-dropdown-link-active-bg: none !important
}

.searchbar {
  .input-group-text {
    background: none !important;
    background: #fff !important;
    border: none !important;
  }

  .search_cat_dropdown {


    font-size: 14px;
  }
}

h5 {
  margin-bottom: none !important;
}

.iconsheader {
  // font-size: 20px;
  cursor: pointer;
  margin: 10px;
}

.hedar-search-icons-maindiv {
  // padding-right: 12px;
}

.Header-discountsection {
  width: 176px;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.discount-div {
  display: flex;
  justify-content: center;
  width: 48px;
  height: 48px;
  padding: 15px 0px 0px 0px;
  gap: 0px;
  border-radius: 100px;

  background: #192253;
}

.discount-icon {
  font-size: 18px;
}

.discount-rightside {
  width: 120px;
  height: 44px;
  padding-left: 2px;

}

.discount-righttop {
  //styleName: Body/Extrasmall;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #CAD0D9;
}

.discount-rightbottom {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  width: 120px;
  height: 24px;
}

.header-bottom {
  height: 40px;

  margin: 0px 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .category_section {
    // display: flex;
    // flex-direction: row;

  }

  .categoriesdiv {
 
    padding: 5px 20px;
    border-radius: 8px 8px 0px 0px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .categoryitem {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .categoryiconhedaer {

      color: #E0E5EB;
    }
  }

  .header-navlinks {
    padding: 5px 20px;
  }

  .header-languages {
    //styleName: Navigation/Nav link small;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    width: 48px;
    height: 48px;
    color: #FFFFFFCC;


  }

  .languages_arrow {
    margin-left: 10px;
    color: #E0E5EB;
  }

  .header-navlinks-title {
    //styleName: Navigation/Nav link regular;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    // color: #FFFFFFCC;


  }


  .header-category-title {
    //styleName: Navigation/Nav link regular;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    padding-left: 5px;
    text-align: left;
    width: 200px;
    color: #E0E5EB;

  }
}

.headerflag {
  width: 29.65px;
  height: 19.76px;
  top: 0.12px;
  left: 0.04px;
  gap: 0px;
  border-radius: 3.95px 0px 0px 0px;

}

// ........................................searchbar
.searchbar {
  // width: 503px;

  text-align: center;



}