ul {
    list-style: outside none none;
    margin: 0;
    padding: 0;
}

.drop-icon {
    // color: #323232;
    text-decoration: none;
}

.header-bottom-list {
    margin-bottom: unset;
}

.ht-dropdown {
    background: #fff;
    left: 0;
    opacity: 0;
    padding: 10px;
    position: absolute;
    top: 40px;
    -webkiit-transform: scaleY(0);
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 0 0 0;
    transform-origin: 0 0 0;
    width: 170px;
    visibility: hidden;
    z-index: 9999;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    -webkit-box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.1);
    -ms-box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.1);

    li {
        a {
            // color: #323232;
            text-decoration: none;
        }
    }

    .sub_sub_cat {
        padding-left: unset;
        // border-right: 1px solid $border_color;
    }
}

.header-top-left>ul>li:hover ul.ht-dropdown,
.header-top-right>ul>li:hover ul.ht-dropdown,
.cart-box>ul>li:hover .ht-dropdown,
.vertical-menu-list>li:hover ul.ht-dropdown,
.header-bottom-list li:hover ul.ht-dropdown {
    opacity: 1;
    visibility: visible;
    -webkiit-transform: scaleY(1);
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
}

.header-top-left ul>li:hover ul.ht-dropdown li {
    padding: 0;
}

.header-top-left ul>li ul.ht-dropdown li a {
    color: #2c2c2c;
    font-size: 13px;
    line-height: 30px;
}

/* ---Header Top Center--- */
.header-top_center span {
    color: #777777;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 12px;
}

/* other home version header css start here */
.header-style-two {
    margin-bottom: 40px;
}

.header-style-two .header-top {
    border-bottom: none;
}

.header-style-two .header-top {
    padding: 20px 0 12px;
}

.cart-box-right .ht-dropdown {
    left: auto;
    right: 0;
}

.header-style-two .header-top-right ul>li>ul.ht-dropdown {
    left: 0;
    right: auto;
}

.header-style-two .header-top-right>ul>li {
    padding: 30px 0;
}

.header-style-two .header-bottom-list>li>a {
    padding: 20px 20px 20px 0;
}

.header-style-two>ul>li {
    padding: 30px 0 20px;
    position: relative;
}

.header-style-four .header-top {
    padding: 15px 0;
}

.header-style-four .header-top-left>ul>li:not(:last-child) {
    margin-right: 15px;
}

.header-style-four .header-middle {
    padding: 10px 0;
}

.header-style-four .header-middle.sticky {
    padding: 0;
}

.categorie-search-box {
    left: auto;
    padding: 0;
    right: 0;
    width: 400px;
}

.categorie-search-box form {
    color: #a9a9a9;
    padding: 0;
    position: relative;
    width: 100%;
}

.categorie-search-box input,
.subscribe-box input {
    background: #fafafa none repeat scroll 0 0;
    color: #323232;
    font-size: 13px;
    height: 45px;
    padding: 10px 45px 10px 15px;
    width: 100%;
    border: none;
}

.categorie-search-box button {
    background: transparent none repeat scroll 0 0;
    border: 0 none;
    height: 45px;
    line-height: 55px;
    padding: 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    -webkit-transition: all 300ms ease-in 0s;
    transition: all 300ms ease-in 0s;
    width: 45px;
}

.categorie-search-box button span {
    font-size: 25px;
}

.categorie-search-box button:focus {
    border: none;
}

.categorie-search-box .form-group {
    background: transparent none repeat scroll 0 0;
    display: inline-block;
    left: 0;
    margin: 0;
    position: absolute;
    top: 12px;
    width: 150px;
}

/* --Header Form Area-- */
.header-form_area form.hm-searchbox {
    position: relative;
}

.header-form_area form.hm-searchbox input {
    background-color: #ffffff;
    height: 40px;
    line-height: 40px;
    width: 100%;
    padding: 0 60px 0 20px;
    border: 1px solid #e5e5e5;
}

.header-form_area form.hm-searchbox button.search-btn {
    background: transparent;
    border: 0;
    height: 40px;
    line-height: 50px;
    padding: 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    width: 40px;
}

.header-form_area form.hm-searchbox button.search-btn i {
    font-size: 22px;
}

.bootstrap-select option {
    font-size: 13px;
}

.nice-select::after {
    margin-top: -3px;
}

.nice-select .current {
    display: block;
    overflow: hidden;
    width: 100%;
}

.categorie-search-box .nice-select .list {
    height: 350px;
    overflow-y: auto;
}

.bootstrap-select {
    border-radius: 15px 0 0 15px;
    border-style: none solid none none;
    border-width: 0 1px 0 0;
    color: #777777;
    font-size: 13px;
    height: 25px;
    line-height: 25px;
    margin: 0;
    width: 150px;
    background: #ededed;
}

.cart-box>ul {
    -moz-box-pack: end;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.cart-box>ul>li {
    position: relative;
    padding: 30px 0;
    line-height: 1;
}

.cart-box-width {
    left: auto;
    right: 0;
    background: white;
    -webkit-box-shadow: 0 3px 9.3px 0.7px rgba(0, 0, 0, 0.15);
    box-shadow: 0 3px 9.3px 0.7px rgba(0, 0, 0, 0.15);
    padding: 0;
    width: 290px;
}

.cart-box-width>li {
    padding: 20px;
}

.cart-box>ul>li>a>span {
    font-size: 25px;
}

.cart-box>ul>li>a {
    color: #323232;
    margin-left: 20px;
}

.cart-text {
    display: block;
}

.single-cart-box {
    margin-bottom: 18px;
    overflow: hidden;
    position: relative;
}

.cart-img {
    float: left;
    padding-right: 10px;
    position: relative;
    width: 35%;
}

.cart-img img {
    max-width: 100%;
}

.cart-content {
    float: left;
    padding: 0 15px 15px 0;
    width: 65%;
}

.cart-content h6 a {
    display: block;
    font-size: 14px;
    line-height: 20px;
    overflow: hidden;
    overflow-wrap: break-word;
    text-overflow: ellipsis;
    text-transform: capitalize;
    white-space: nowrap;
}

.cart-content span {
    display: block;
    font-size: 14px;
    line-height: 20px;
}

.cart-price {
    margin: 5px 0;
}

.cart-actions a {
    background: #2c2c2c none repeat scroll 0 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: white;
    display: block;
    font-size: 14px;
    font-weight: 400;
    padding: 10px 20px;
}

.cart-checkout {
    background: #f26667 none repeat scroll 0 0;
    color: #fff;
}

.del-icone {
    color: #666;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 45;
}

.wish-list-item {
    position: relative;
    padding-right: 30px;
    margin-right: 30px;
}

.cart-box>ul>li>a span+span.total-pro {
    border-radius: 100%;
    color: #fff;
    font-size: 11px;
    height: 18px;
    left: 32px;
    line-height: 18px;
    position: absolute;
    text-align: center;
    top: 20px;
    width: 18px;
}

.single-cart-box,
.price-content {
    border-bottom: 1px solid #ededed;
    margin-bottom: 20px;
}

.price-content {
    overflow: hidden;
    padding-bottom: 20px;
}

.price-content li {
    font-size: 15px;
    font-weight: 500;
    line-height: 1.5;
}

.price-content li span,
.cart-content span.cart-price {
    float: right;
    font-size: 15px;
    font-weight: 500;
}

.cart-content span.cart-price {
    float: none;
}

.pro-quantity {
    border-radius: 100%;
    color: white;
    font-size: 12px;
    left: 5px;
    line-height: 23px;
    min-width: 25px;
    padding: 2px 0 0;
    position: absolute;
    text-align: center;
    top: 3px;
}

/*----------------------------------------*/
/* 3.02 Header Bottom CSS
/*----------------------------------------*/
.header-sticky.sticky {
    background: #fff;
    -webkit-box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.4);
    box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.4);
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-transition: all 300ms ease-in 0s;
    transition: all 300ms ease-in 0s;
    z-index: 1049;
}

.sticky {
    -webkit-animation: 800ms ease-in-out 0s normal none 1 running fadeInDown;
    animation: 800ms ease-in-out 0s normal none 1 running fadeInDown;
}

.header-bottom-list>li {
    margin-right: 5px;
    position: relative;
}

/* header bottom dropdown menu css start */
.header-bottom-list>li>a,
.header-bottom-list>li>ul.ht-dropdown li a {
    display: block;
    // font-size: 14px;
    // font-weight: 500;
    padding: 5px 25px 5px 0;
    position: relative;
    text-transform: capitalize;
}

.header-bottom-list>li>ul.ht-dropdown li a,
.header-top-right>ul>li>ul.ht-dropdown li a,
.cart-box-right ul>li>a {
    display: block;
    font-size: 12px;
    font-weight: 400;
    text-transform: capitalize;
    text-align: left;
}

.header-top-right>ul>li>ul.ht-dropdown li a {
    font-size: 13px;
}

.header-top-right>ul>li>ul.ht-dropdown li:not(:last-child) a {
    border-bottom: 1px solid #f2f2f2;
}

.header-top-right ul>li>ul.ht-dropdown {
    width: 155px;
}

.header-top-right ul>li>ul.ht-dropdown {
    left: auto;
    right: 0;
}

.category-menu-title {
    display: block;
    cursor: pointer;
    // font-size: 13px;
    // font-weight: 600;
    line-height: 20px;
    margin-bottom: 10px;
}

.category-menu-title:hover {
    // color: #535353
}

.sub_sub_category_title:hover {
    // color: $primary_color
}



/* header bottom dropdown menu css end */
.header-bottom-list>li.active>a,
.header-bottom-list>li:hover>a {
    // color: $primary_color;
    // border-bottom: 1px solid $primary_color;
    position: relative;
}

.header-right i {
    font-size: 23px;
    margin-right: 15px;
    vertical-align: middle;
}

.header-helpline {
    font-size: 15px;
    font-weight: 600;
}

/* header bottom megamenu  css end */
.megamenu {
    background: #fff none repeat scroll 0 0;
    border: 1px solid #e5e5e3;
    padding: 20px 25px;
}

.header-bottom-list ul.ht-dropdown.megamenu {
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.header-bottom-list ul.ht-dropdown.megamenu li a {
    padding-left: 0;
}

.header-bottom-list ul.ht-dropdown.megamenu-two {
    width: 400px;
}

.header-bottom-list ul.ht-dropdown.megamenu-three>li:last-child {
    margin-right: 0;
}

.header-bottom-list ul.ht-dropdown.megamenu-two>li {
    width: 50%;
}

.header-bottom-list ul.ht-dropdown.megamenu-three {
    width: 692px;
}

.header-bottom-list ul.ht-dropdown.megamenu-three>li {
    width: fit-content;
}

.header-bottom-list ul.ht-dropdown.megamenu-two {
    width: 460px;
}

.header-bottom-list ul.ht-dropdown.megamenu-two>li {
    width: 50%;
    margin-right: 30px;
}




// a {
// 	color: #18272F;
//   position: relative;
//   text-decoration: none;
// }

// a::before {
//   content: '';
//   position: absolute;
//   width: 100%;
//   height: 4px;
//   border-radius: 4px;
//   background-color: #18272F;
//   bottom: 0;
//   left: 0;
//   transform-origin: right;
//   transform: scaleX(0);
//   transition: transform .3s ease-in-out;
// }

// a:hover::before {
//   transform-origin: left;
//   transform: scaleX(1);
// }



// .page-item.active .page-link {
//     background-color: $primary_color;
//     border: none !important;
//     border-color: $primary_color;
// }

// .page-item:not(.active,.disabled) .page-link {
//     color: $primary_color !important;
// }

// .page-link:focus{
//     box-shadow: none !important;
// }