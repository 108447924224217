.Blogdetails-page {
    position: relative;

}

.Blog-pagecontent {
    position: absolute;
    top: 50%;
    left: 5%;


}

.blogdetail-image {
    width: 100%;
    height: 350px;
    object-fit: cover;
}

.Blogcontent {
    font-size: 42px;
    font-weight: 500;
    color: #fff;
    margin-bottom: 0;
    flex: 1;
    padding: 10px 0;
}

@media only screen and (max-width: 600px) {
    .blogdetail-image {
        height: 200px;

    }
}

// ....................................Blog
.Blogcard {
    background: #fff;
    box-shadow: 0 3px 16px 0 #0000001c;
}

.Blogproduct {
    margin: 10px 0px !important;
}

.Blogarrow {

    display: flex ;
    justify-content: end !important;
    // text-align: end !important;
    // align-items: end !important;
    // width: fit-content;
    // position: relative;
}

.BlogArrowicon {
    border: 1px solid lightgray;
    padding: 10px 15px;
    margin: 10px !important;
    // position: absolute;
    right: 15px;
    top: 50%;
}