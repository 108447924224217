:root {
    --hue: 223;
    --bg: hsl(var(--hue), 10%, 90%);
    --fg: hsl(var(--hue), 10%, 10%);
    --trans-dur: 0.3s;
}

.preloader {
    // color: $primary_color;
}

.homepage-top-space {


    @media only screen and (min-width: 991px) {
        margin-top: 150px;
        padding: 0px 22px;
    }
}

.home_carousel_img {
    width: 100% !important;

    object-fit: cover !important;
    border-radius: 10px;

    // opacity: 0.1;
    // aspect-ratio: 5.5/2.5;
    @media only screen and (min-width: 576px) {
        // aspect-ratio: 3/2.5;
    }

    @media only screen and (max-width: 576px) {
        height: 209px !important;
        // object-fit: contain;
    }
}



.home-custom-container .carousel-indicators {
    .active {
        background: $primary_color !important;
        border: 2px solid $primary_color !important;
    }


    @media only screen and (min-width: 768px) {
        bottom: 40px
    }

    @media only screen and (min-width: 991px) {
        bottom: 140px
    }
}

.home-custom-container .carousel-control-prev {
    // @media only screen and (min-width: 991px) {
    // top: -140px
    // margin-bottom: 120px;
    // top: 0px;
    position: absolute;
    background: $primary_color !important;
    top: 46%;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    padding: 0;
    color: #fff;
    text-align: center;
    background: none;
    border: 0;
    opacity: 1;
    transition: opacity 0.15s ease;
    // }
}

@keyframes zoomInRight {
    0% {
        transform: translateX(50%) scale(0.1);
        opacity: 0;
    }

    100% {
        transform: translateX(0) scale(1);
        opacity: 1;
    }
}

@keyframes zoomInUp {
    0% {
        transform: scale(0.0);
        opacity: 0;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}



.home-carousel-banner-content {

    position: absolute;
    // bottom: 25%;
    // left: 6%;

    @media only screen and (max-width:576px) {
        // bottom: 20px !important;
    }
}




.home_carousel_img:hover .home-custom-container .carousel-control-prev {
    opacity: 0;

}

.homebanner-cart-symbol {
    width: 20px;
    height: 20px;

    // border: 1px solid black;


    @media only screen and (max-width:576px) {
        font-size: 30px;
        left: -18px;
        bottom: -2px;
        padding: 2px;
    }
}


.carousel-control-next-icon {
    width: 1rem !important;
    height: 1rem !important;
}

.carousel-control-prev-icon {
    width: 1rem !important;
    height: 1rem !important;
}


.space-30 {
    margin: 15px;
}

.top-space {
    padding-top: 20px;
}

// @media only screen and (max-width:576px) {
//     .homebanner-title {
//         font-size: 22px;
//         font-weight: 600;
//     }

//     .homebanner-description {
//         font-size: 14px;
//         font-weight: 500;
//     }
// }
.homebanner-title {
    -webkit-animation: 1600ms ease-in-out 0s normal none 1 running zoomInUp;
    animation: 1600ms ease-in-out 0s normal none 1 running zoomInUp;

    @media only screen and (max-width:576px) {

        // color: #fff;
        -webkit-animation: 1600ms ease-in-out 0s normal none 1 running zoomInUp;
        animation: 1600ms ease-in-out 0s normal none 1 running zoomInUp;
    }
}

.homebanner-description {
    -webkit-animation: 2000ms ease-in-out 0s normal none 1 running zoomInUp;
    animation: 2000ms ease-in-out 0s normal none 1 running zoomInUp;

    @media only screen and (max-width:576px) {
        -webkit-animation: 2000ms ease-in-out 0s normal none 1 running zoomInUp;
        animation: 2000ms ease-in-out 0s normal none 1 running zoomInUp;

        // color: #fff;
    }
}

.homebanner-cart-main-div {
    padding: 10px;
    // background: #657150;
    // color: #fff;
}

.homepage-components {
    margin-bottom: 30px !important;
}

.homebanner-button-slider {

    margin-top: 20px;
    text-align: center;
    width: 23%;
    text-transform: uppercase;
    position: relative;
    -webkit-animation: 2500ms ease-in-out 0s normal none 1 running zoomInRight;
    animation: 2500ms ease-in-out 0s normal none 1 running zoomInRight;

    @media only screen and (max-width:576px) {
        width: fit-content;
        -webkit-animation: 2500ms ease-in-out 0s normal none 1 running zoomInRight;
        animation: 2500ms ease-in-out 0s normal none 1 running zoomInRight;
    }
}

.slider-progress {
    -webkit-animation: 5000ms ease-in-out 0s normal none 1 running timebar;
    animation: 5000ms ease-in-out 0s normal none 1 running timebar;
    background: rgba(0, 0, 0, .3) !important;
    height: 5px !important;
    left: 0;
    opacity: 0.8;
    position: absolute;
    // width: 100% !important;
    top: 0;
    z-index: 4;
    // color: red !important;
    // background-color: red !important;
}

@-webkit-keyframes timebar {
    0% {
        width: 0;
    }

    100% {
        width: 100%;
    }
}

@keyframes timebar {
    0% {
        width: 0;
    }

    100% {
        width: 100%;
    }
}

@keyframes timebar {
    0% {
        width: 0;
    }

    100% {
        width: 100%;
    }
}

// ......................................................................productcard
.dual-pro .single-makal-product:first-child {
    margin-bottom: 55px;
}

.productcard-price-symbol {
    font-size: 12px !important;
    font-weight: 400 !important;
    color: gray !important;
}

// .Heading-main{
//     display: flex;
//     flex-direction: row;
//     justify-content: space-around;
// }
.viewallbutton {
    padding: 0px 20px 5px 0px;
    text-align: end;
}

.productcard-rating {
    position: absolute;
    display: flex;
    bottom: 10px;
    left: 10px;
    margin-bottom: 5px;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    gap: 2px;
    border-radius: 20px;
    background: #FFEBEB;
    padding: 1px 5px;
    z-index: 1;
}

.addcarticons {
    padding-bottom: 3px !important;
}

.productcard-status-discount {
    display: flex;
    position: absolute;
    top: 10px;
    left: 10px;
    flex-direction: row;
}

.productcard-heart {
    display: flex;
    position: absolute;
    width: 24px;
    height: 25px;
    top: 12px;
    right: 10px;

    border-radius: 52px 0px 0px 0px;


    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    color: white;
    // background: #E33434;
}

.wishlisticon-heart {
    // width: 47.56px;
    // height: 15.1px;
    // color: #FFFFFF;

}

.productcard-rating-carticon {
    width: 35px;
    // height: 90px;
    gap: 26px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: end;
}

.product-rating {
    width: 49px;
    display: flex;
    justify-content: end;
}

.productrate {
    width: 31px;
    height: 18px;
    //styleName: Body Text/12px - Regular;
    font-family: Be Vietnam Pro;
    font-size: 10px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: #999CA1;
}

.product-rating-icons {
    width: 14px;
    height: 13.42px;
    top: 0.03px;
    color: #FFC107;
}

.productcard-carticon {
    display: flex;
    flex-direction: row;
    visibility: visible;
    pointer-events: auto;
    width: 30px;
    height: 30px;
    // padding: 12px 11px 10px 11px;

    border-radius: 46px 0px 0px 0px;

    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    border-radius: 50px;
    // background: var(--Pink---Deals-247, #C23A97);
}

.product-card-cart-icon {
    width: 20px;
    height: 20px;
    color: #F6FCFF;
}

.productcars-wishlisted {
    // background: #FFEBEB !important;


}

.productcars-wishlistedicon {
    // color: #E33434 !important;

}

.wishlist-card-wrong {
    position: relative;
}

.Heading-discription {
    font-weight: none !important;
}

.whishlist-wrong-button {
    position: absolute;
    top: 10px;
    // z-index: 10;
    right: 10px;
    font-size: 24px;
}

.pro-img {
    position: relative;
}

.pro-img img {
    width: 100%;
    // height: 191px;
    object-fit: contain;
    // border-radius: 10px;
    background: #f8f9fa;

}
.currencysymbol{
    padding-right: 2px;
}

.pro-content {
    // padding: 20px 0 0;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: end;
    justify-content: space-between;
    min-height: 35px;
    max-height: 70px;
}
.pricecardproduct{
    font-size: 10px !important;
}
.product-card-title-price {
    // padding-right: 10px;
}

.rating i {
    font-size: 12px;
}

.detailimages {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.swiper-padd {
    padding: 0px 5px;
}
.producttitle-height{
    height: 35px;
}
.pro-title {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    padding-right: 5px;

    line-height: 18px !important;
    overflow: hidden;
    /* text-overflow: ellipsis; */
    font-family: Be Vietnam Pro;
 

    @media only screen and (max-width:576px) {
        // max-width: 80px;
    }
}

.list-title {
    height: 39px !important;
}




.pro-content p {
    line-height: 1;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}

.price {

    padding-right: 5px;
    width: 79px;
    height: 20px;
    gap: 0px;
    font-family: Be Vietnam Pro;
    font-size: 14px;
    font-weight: 800;
    line-height: 20px;
    text-align: left;

}

.prev-price {
    color: #595959;
    font-size: 14px;
    font-weight: 400;
    text-decoration: line-through;
}

.pro-actions {
    -moz-box-align: center;
    -moz-box-pack: justify;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    bottom: -4px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;

    position: absolute;
    -webkit-transition: all 0.5s ease-in-out 0s;
    transition: all 0.5s ease-in-out 0s;
    width: 100%;
}

.single-makal-product:hover .pro-actions {
    opacity: 1;
}

.actions-primary,
.actions-secondary {
    opacity: 1;
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
    -webkit-transition: all 0.5s ease-in-out 0s;
    transition: all 0.5s ease-in-out 0s;
}

.single-makal-product:hover .actions-primary,
.single-makal-product:hover .actions-secondary {
    -webkit-transform: scale(1);
    transform: scale(1);
}

// .single-makal-product:hover .pro-content p {
//     opacity: 0;
// }

// #list-view .single-makal-product:hover .pro-content p {
//     opacity: 1;
// }

.add-to-cart {
    // display: inline-block;

    // line-height: 30px;
    cursor: pointer;
    padding-top: 5px;
    text-transform: capitalize;
}

.actions-secondary a {
    display: inline-block;
    height: 30px;
    line-height: 36px;
    width: 30px;
}

// @media screen and (max-width:600px) {

//     /* Zoom effect for the active (centered) slide */


//     .carousel-swiper {
//         // padding: 30px 0px !important;

//         .swiper-slide-active {
//             // transform: scale(1.2) !important;
//             transition: transform 0.3s ease !important;
//             z-index: 100 !important;
//         }

//         // // /* Optional: If you want to apply some shadow or highlight to the zoomed item */
//         .swiper-slide-active {
//             box-shadow: 0 4px 15px rgba(181, 195, 221, 0.2);
//             // padding: 20px 0px !important;
//             background: #fff;
//         }

//         .swiper-slide:not(.swiper-slide-active) {
//             filter: blur(2px);
//             transform: scale(0.9);
//         }
//     }
// }





// .specific-quick-view:before {
//     box-shadow: 1px 4px 5px 0px rgba(0, 0, 0, 0.11);
//     color: #343434;
// }

.single-makal-product:hover .quick-view::before {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.sticker-new,
.sticker-sale {
    border-radius: 0;

    // display: inline-block;

    height: 25px;
    // left: 20px;
    line-height: 25px;
    min-width: 50px;
    padding: 0 5px;
    // position: absolute;
    text-align: center;
    text-transform: capitalize;
    // top: 20px;
    z-index: 5;
}

.sticker-sale {
    // left: 75px;
    // margin-left: 10px;
}


.previousbtn {

    cursor: pointer;
    display: block;
    left: 0;
    line-height: 37px;
    opacity: 0;
    position: absolute;
    text-align: center;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: all 0.5s ease-in-out 0s;
    transition: all 0.5s ease-in-out 0s;
    // pointer-events: none;
    z-index: 8;
}

.nextbtn {

    cursor: pointer;
    display: block;
    right: 0;
    line-height: 37px;
    position: absolute;
    text-align: center;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: all 0.5s ease-in-out 0s;
    transition: all 0.5s ease-in-out 0s;

    z-index: 8;
}

.swiper-button-disabled {
    // opacity: .2 !important;
    // color: #000 !important;
    // background: rgba(0, 0, 0, 0.2);
    // pointer-events: none !important;
    z-index: 1 !important;
}

.swiper-main {

    position: relative;
    // margin-top:50px;
}

.swiper-main:hover .nextbtn {
    opacity: 1;
}

.swiper-main:hover .previousbtn {
    opacity: 1;
}

.nextbtn:hover {
    // background: $primary_color;
}

.previousbtn:hover {
    // background: $primary_color;
}


.section-title {
    margin-top: 50px;
    margin-bottom: 50px;

}

@media only screen and (max-width: 600px) {
    .section-title {
        margin-top: 0px;
        margin-bottom: 10px;

    }
}

.section-title p {
    // letter-spacing: 1px;
    // line-height: 24px;
    padding: 0 5%;
    margin: auto;
}

.countdown {
    bottom: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 5px 0;
    text-align: center;
    margin-top: 35px;
    margin: 0px 2px !important;
    padding-right: 2px !important;
    padding-left: 2px !important;
}

.daily-deal-active .countdown {
    margin-top: 0;
    padding-bottom: 15px;
    padding-top: 0;
}

.count {

    padding: 5px;
    text-align: center;
    background: #fff;
    border-radius: 10px;
}

// .count-status-title {
// font-size: 12px;
// }

.count-main {

    padding-right: none !important;
    padding-left: none !important;
    padding: 5px !important;
}

.countdown .count:not(:last-child) {
    margin-right: 10px;
}

.dropdown-toggle::after {
    border-right: unset !important;
    border-left: unset !important;
}

.varient-list {
    border: 1px solid lightgrey;

    padding: 0px 10px;
    background: #ebebeb;
    // margin:  5px;
    border-radius: 50px;
    // text-align: center;
    display: flex;
    align-items: center;
    // justify-content: space-between;
}

.varient-name {
    color: #000;
    font-size: 14px;

}

.varientprice {
    color: #323232;
    padding-left: 5px;
    font-size: 12px;
}

.varient-swiper {
    position: relative;
    padding: 0px 20px;
    margin: 10px 0px;

}

// .leftbutton {
//     position: absolute;
//     left: -2px;
//     top: -6px;

//     z-index: 1;

//     font-size: 19px;

//     padding: 0;
//     // color: #fff;
//     text-align: center;
//     background: none;
//     border: 0;
//     opacity: 1;
//     transition: opacity 0.15s ease;
// }
// .rightbutton {
//     position: absolute;
//     right: -2px;
//     top: -6px;
//     font-size: 19px;

//     padding: 0px;
//     margin-left: 0px;
//     z-index: 1;


//     justify-content: center;

//     background: none;

//     opacity: 1;
//     transition: opacity 0.15s ease;
// }

// ........................homepagebrands
.homepagebrands-images {
    width: 100%;
    object-fit: cover;
}

.product-card-variant-dropdownlist {
    // box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.11);
    background: white;

    .product-card-variant-listitem {
        border: 1px solid $border_color;
        padding: 5px;
        border-radius: 2px;
        margin: 8px;
        width: unset !important;

        @media only screen and (max-width: 991px) {
            // margin: 15px;
        }

        .variant_title {
            font-size: 14px;
        }

        &:hover {
            box-shadow: rgba(48, 48, 48, 0.04) 0px 0px 1px, rgba(96, 96, 96, 0.16) 0px 2px 4px;
        }
    }

    .selected_variant_active {
        border: 2px solid black;
    }
}

.product-price {
    align-items: center;
    display: flex;

    .varientsellingprice {
        font-size: 15px;
        display: inline;
        color: $black;
        font-weight: 700;

    }

    .varient-mrp {
        display: inline-block !important;
        margin-left: 7px;
        text-decoration: line-through;
        font-size: 12px;
    }


}






.Varient-details {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.varientaddtocart {
    font-size: 12px;
    padding: 0px 5px;
    bottom: 20px;
    border-radius: 3px;
}


.selected {
    background: rgba(64, 64, 64);
    color: #fff;
}

/* Add this to your CSS file */
.slide-up-enter {
    transform: translateY(100%);
    transition: transform 0.3s ease-in-out;
}

.slide-up-enter-active {
    transform: translateY(0);
}

.slide-up-exit {
    transform: translateY(0);
    transition: transform 0.3s ease-in-out;
}

.slide-up-exit-active {
    transform: translateY(100%);
}

.sheet-container {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: white;
    box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    border-radius: 12px 12px 0 0;
    padding: 20px;
    max-height: 80vh;
    /* Adjust the height to your needs */
    overflow-y: auto;
    /* Enables vertical scrolling */
}

/* Prevent scrolling on the body */
.no-scroll {
    overflow: hidden;
}

/* Positioning the close icon */
.close-icon-container {
    position: absolute;
    top: 10px;
    right: 10px;
}

.close-icon {
    font-size: 24px;
    margin: 10px;
    cursor: pointer;
}

.mobile-varient-title {
    font-size: 16px;
    font-weight: 600;
}

/* Background overlay for when the sheet is open */
.background-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
    opacity: 100;
    transition: opacity 0.3s ease-in-out;
}

/* Make the background visible when the sheet is open */
.background-overlay.show {
    opacity: 1;
}

.no-scroll {
    overflow: hidden;
}




















// .....................................................Homeimagecard
.homepage_single_banner {
    width: 100%;
}

.single_banner-img {
    width: 100%;
    object-fit: cover;
}

.single-categorie .cat-img {
    position: relative;
    overflow: hidden;
}

.single-categorie .cat-img img {
    -webkit-transition: all 300ms ease-in 0s;
    transition: all 300ms ease-in 0s;
    width: 100%;
    object-fit: cover;
}

.single-categorie:hover .cat-img img {
    -webkit-transform: scale(1.03);
    transform: scale(1.03);
}

.cat-content {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.cat-img {
    // height: 200px;
}

.catcontent-titles {

    display: inline-block;

    padding: 15px 33px;
    -webkit-transition: all 300ms ease-in 0s;
    transition: all 300ms ease-in 0s;
}

.multi-banner .cat-content a {
    background: #323232 none repeat scroll 0 0;
    color: #fff;
    text-transform: uppercase;
}

.multi-banner .cat-content {
    bottom: 35px;
    top: auto;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
}





// // ..................................clients


.testmonial-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-bottom: 150px;
}

.testmonial-discription {
    max-width: 600px;
    line-height: 24px !important;
}

.clientimage {
    margin: 15px 0px;
    border-radius: 50%;
}


// .......................................................Blogposts

.carousel_image {

    .images-hover-sub-div:hover .Singleimage-main-image-div .Singleimage-image,
    .images-hover:hover .Singleimage-main-image-div .Singleimage-image {


        -webkit-transform: none !important;
        -moz-transform: none !important;
        -ms-transform: none !important;
        -o-transform: none !important;
        transform: none !important;
        -webkit-transform: none !important;
        transform: none !important;
        -ms-filter: none !important;
        filter: none !important;
        -webkit-transition: none !important;
        -o-transition: none !important;
        transition: none !important;
    }

    .images-hover-sub-div:hover .Singleimage-main-image-div:before {
        background: none !important;
    }

}

.Singleimage-main-image-div {
    max-width: 100%;
    position: relative;
    overflow: hidden;
}

.Singleimage-image {
    display: block;
    width: 100%;
    // aspect-ratio: 1/1;
    // height: 250px;
    // border-radius: 5px;
    // -webkit-transition: all .3s ease-out;
    // -o-transition: all .3s ease-out;
    // transition: all .3s ease-out;
}

.images-hover-sub-div:hover .Singleimage-main-image-div .Singleimage-image,
.images-hover:hover .Singleimage-main-image-div .Singleimage-image {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
    -webkit-transform: scale(1.1) rotate(2deg);
    transform: scale(1.1) rotate(2deg);
    -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=31.01)";
    filter: alpha(opacity=30.01);
    -webkit-transition: all .5s ease-out;
    -o-transition: all .5s ease-out;
    transition: all .5s ease-out;
}

.images-hover-sub-div:hover .Singleimage-main-image-div .Singleimage-image {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
    -webkit-transform: scale(1.1) rotate(2deg);
    transform: scale(1.1) rotate(2deg);
    -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=30)";
    filter: alpha(opacity=30);
    -webkit-transition: all .5s ease-out;
    -o-transition: all .5s ease-out;
    transition: all .5s ease-out;
}

.images-hover-sub-div:hover .Singleimage-main-image-div:before {
    // width: 100%;
    // border-radius: 5px !important;
    // content:"";
    // background:rgba(0, 0, 0, 0.5);
    // z-index:1;
    // position: absolute;
    // top: 0;
    // left: 0;
    // width: 100%;
    // height: 100%;
    // transition: 0.3s background ease-out;
    // -webkit-transition: all .5s ease-out;
    // -o-transition: all .5s ease-out;
    // transition: all .5s ease-out;
}


.singleimage-button {

    display: inline-block;
    line-height: 55px;

    text-decoration: none;
    text-transform: capitalize;
    -webkit-transition: all 300ms ease-in 0s;
    transition: all 300ms ease-in 0s;
    margin-top: 25px;
    text-align: center;

}



.Singleimage-image-discription {
    // font-size: 18px;

    line-height: 24px;
    margin-bottom: 0;
}

.Singleimage-image-title {
    margin: 10px;
    display: block;
    margin-bottom: 15px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    /* height: 50px; */
    margin-top: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: capitalize;
    font-size: 22px;
}

.heading {
    font-size: 14px;
    line-height: 1rem;
}

.Heading-line {
    border-bottom: 2px dotted black;
    max-width: 400px;
    display: flex;
    justify-content: center;

}

.Singleimage-main-image-div {
    position: relative;
}

.Singleimage-main-image-div-images {
    position: absolute;
    top: 10px;
    left: 5px;
    margin: unset !important;
    padding: unset !important;
}

.Singleimage-image-large-discription {
    margin: 0px 0px 10px 0px;
    // max-height: 3rem;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    /* Number of lines to show */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    // white-space: nowrap;
}

.Singleimage-maindiv {
    display: flex;
    font-size: 14px;

    .Singleimage-author {
        padding: 5px 0px;

    }

    .Singleimage-1 {
        margin-right: 10px;
    }

    .Singleimage-author-icon {

        padding: 2px;
        margin-right: 5px;
        border-radius: 2px;

        align-items: center;
    }

    .icondata {
        font-size: 12px;
    }
}

// ......................................threecarousel
.smallcomp-product {
    display: flex;
    flex-direction: row;
    margin: 5px 0px;
}



.smallcomp-product-content {
    margin-left: 10px;
}

.previousbtns,
.nextbtns {
    opacity: 1 !important;

}

.threecarousel-top {
    display: flex;
    justify-content: space-between;

}

.threecarousel-btns {
    display: flex;
}

.threecarousel-component {
    padding: 10px;
}

// ....................................ourproducts
.main-ourproducts {
    display: flex;
    /* flex-wrap: wrap; */
    /* justify-content: center; */
    align-items: center;
    padding-bottom: 10px;
    overflow-x: scroll;
    overflow-y: hidden;
    width: 100%;
    white-space: nowrap;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

@media only screen and (min-width:768px) {
    .main-ourproducts {
        // justify-content: center;
        position: relative;
    }
}

.ourprodutscat {
    width: fit-content;
    height: 45px;
    gap: 0px;
    border-radius: 115px;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 5px;
    border: 1px solid lightgrey;
    cursor: pointer;

}



.activemultiplelist {
    background: var(--Pink---Deals-247, #C23A97);
    color: #FFFFFF;
}


.multiplecarouseltitle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 33px;
    height: 33px;
    top: 8px;
    left: 8px;
    gap: 0px;
    border-radius: 71px;
    background: #FFFFFF;

}


.mutiplecarouseltitlesname {
    //styleName: Body Text/16px - Regular;
    font-family: Be Vietnam Pro;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    padding-left: 10px;

}


.Multiplecarouselviewall {
    display: flex;
    justify-content: end;
    border-bottom: 1px solid #000;
    color: #000;
    cursor: pointer;
    font-family: Raleway;
    font-size: 18px;
    font-weight: 500;
    line-height: 14px;
    text-align: left;

}


@media only screen and (min-width:768px) {

    .Multiplecarouselviewall {
        position: absolute;
        top: 50%;
        right: 10px;
    }
}

// ..............................................productcard1
.productcard1-images {
    width: 145px;
    @media only screen and (max-width: 991px) {
        width: 100px;
        

    }
}

.addd {
    background-color: red;
}

.productcard1-main {
    display: flex !important;
    flex-direction: row;
    width: 100%;
    padding: 20px;

}

@media only screen and (max-width: 600px) {
    .productcard1-main {
        // flex-direction: column !important;
    }
}

.productcard1-discription {
    border-top: 1px solid #ebebeb;
    margin-top: 18px;
    padding-top: 15px;
}

.productcard1-details {
    padding: 10px 40px 10px 20px;
}

@media only screen and (max-width: 600px) {
    .productcard1-details {
        padding: 5px;
    }
}

// ....................................bannercat five banner
.bannercat-title {
    position: absolute;
    bottom: 10px;
    left: 10px;
    padding: 5px 10px;
    text-transform: capitalize;
}

.bannerimage {
    object-fit: cover !important;
}

@media only screen and (max-width: 768px) {
    // .category-image{
    //     height: 150px;
    // }
}

.image_aspect_ratio {
    // aspect-ratio: 3/4;
}

@media only screen and (min-width: 768px) {
    .cat-imag1 {
        height: 400px;
    }

    .cat-imag2 {
        height: 200px;
    }

    .cat-imag3 {
        height: 200px;
    }

    .cat-imag4 {
        height: 200px;
    }

    .cat-imag5 {
        height: 400px;
    }

    .fourbannerimage1 {
        height: 416px;
    }

    .fourbannerimage2 {
        height: 200px;
    }

    .fourbannerimage3 {
        height: 200px;
    }

    .fourbannerimage4 {
        height: 200px;
    }

    // .category-image{
    //     height: 200px;
    // }
}

.category-image {
    aspect-ratio: 1/1;
}

.bannercat-image {
    position: relative;

}

.bannercat-image:hover {
    .bannercat-title {
        // background: #fff;
        // color: red;

        transition: all 0.3s ease-in-out;
    }
}

// .......................................................categortpage
.pagination {
    --bs-pagination-bg: none !important;
}

.cardproduct-main {
    box-shadow: 0px 1.71px 3.43px 0px #0000001F;
    padding: 10px;
    // border-radius: 10px;
    margin: 5px 0px !important;
    @media screen and (max-width:991px){
        margin: 5px 5px !important;
    }
}

.react-pagination-maindiv {
    display: flex;
    // justify-content:space-around;
    // text-align: center;
    margin-bottom: 20px;
    align-items: center;
    // padding: 10px;
    // border: 1px solid #ebebeb;
}

.category-count-div {
    padding: 0px 3px;
    color: #000;
}

.price-category-list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 79px;
    height: 20px;

}

.productcard-prices {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.cross-price {
    text-decoration: line-through;
    padding-left: 5px;
    font-weight: 400;
    // color: gray;
}

.productdiscount {
    font-size: 10px;
    font-weight: 600;
    color: #E33434;
}

.category-top-content {
    font-size: 12px;
    font-weight: 500;
    padding-left: 20px;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: #6C6C6C;
    display: flex;
}

.category-top-lefticons {
    display: flex;
    flex-direction: row;
}

.page-link-number {
    padding: 5px !important;
    border-radius: none !important;
}

.page-link:hover {
    background-color: unset !important;
}

.paginate-link {
    color: black;
    padding: 5px;
    text-decoration: none;
}

.paginate-active {
    color: green;
}

.react-paginate-left {
    font-size: 14px;
    font-weight: 500;
    text-align: center;

    @media only screen and (min-width: 991px) {
        text-align: start;
    }
}

.react-paginate-right {
    margin-top: 10px !important;
    text-decoration: none;
}

.page-item.active .page-link {
    color: #fff;
    background-color: gray !important;
    border-radius: 50%;

    border: unset !important;
    width: 33px;
    text-align: center;
    // border-color: $primary_color;
}

.disabled {
    opacity: 0.1;
    background-color: unset !important;
}

.page-link {
    border: unset !important;
    padding: 5px !important;
}

.page-item {
    padding: 0px 5px;
    height: 30px;
}

// .page-item:not(.active, .disabled) .page-link {
//     color: black !important;
// }

.page-link:focus {
    box-shadow: none !important;
}

.carticons-top {
    font-size: 25px;
    font-weight: 700;
    cursor: pointer;
    margin: 0px 10px;
}

.page-item.active .page-link {
    background-color: black;
    // border: none !important;
    border-color: black;
}

.page-item:not(.active, .disabled) .page-link {
    color: black !important;
}

.page-link:focus {
    box-shadow: none !important;
}

.Heading-discription {
    font-weight: 400 !important;
}


@media only screen and (max-width: 600px) {
    .Heading-title {
        font-size: 25px;

    }

    .Heading-discription {
        font-size: 12px;
        font-weight: 400 !important;
    }
}


.pricecost {
    font-family: Be Vietnam Pro;
    // font-size: 14px;
    // font-weight: 800;
    line-height: 20px;
    text-align: left;

}
@media only screen and (max-width: 600px) {
    .pricecost {
        font-size: 12px;

    }
}
.pricecostcross {
    // font-size: 12px !important;
    // font-weight: 400 !important;
}
@media only screen and (max-width: 600px) {
    .pricecostcross {
        font-size: 10px;

    }

}



// ...................................... footer Dowanload App
.mobile-appimag {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.mobilapp-rightside {
    display: flex;
    justify-content: start;

}

.dowanload-title {
    padding-bottom: 10px;
}

.andriodimg {
    width: 150px !important;
    margin-left: 0px;
    margin-bottom: 10px;
    border-radius: 6px;
    border: 1px solid #fff;
    height: 45px;

}

.iosimag-div {
    width: 150px !important;
    height: 45px;
    border-radius: 6px;
    border: 1px solid #fff;
    margin-bottom: 10px;
}







// ............................................contactformaddress
.contact-page {


    // padding-top: 61px;
    .space-110 {
        width: 100%;
        height: 110px;
    }

    .ot-heading {
        // font-family: $prmary_font;


        .is_highlight {
            position: relative;
            display: inline-block;
            font-size: 11px;
            font-weight: 700;
            text-transform: uppercase;
            color: #323232;
            margin-bottom: 10px;
            background: #55BB531A;
            padding: 2px 10px;
            border: 1px solid #323232;
            -webkit-border-radius: 15px;
            -moz-border-radius: 15px;
            border-radius: 15px;
        }

        .main-head {
            margin-bottom: 0;
            font-size: 36px;
        }
    }

    .space-20 {
        width: 100%;
        height: 20px;
    }

    .space-2 {
        width: 100%;
        height: 2px;
    }

    p {
        margin: 0 0 20px;
    }

    .space-10 {
        width: 100%;
        height: 10px;
    }

    .icon-box {
        transition: all 0.3s linear;
        -webkit-transition: all 0.3s linear;
    }

    .contact-iconb {
        display: flex;

        .icon-main {
            background: #55BB531A;
            text-align: center;
            width: 60px;
            height: 60px;
            font-size: 27px;
            line-height: 60px;
            border-radius: 50% 50% 50% 50%;
        }

        .content-box {
            // margin-left: 76px;
            margin-left: 26px;
            margin-top: 10px;
            max-width: 360px;

            a {
                color: #000;
                text-decoration: none;
            }
        }
    }

    .icon-box-2 {
        &:hover {
            .icon-main {
                box-shadow: 6px 6px 13px 0 rgba(0, 0, 0, 0.15);
                -webkit-box-shadow: 6px 6px 13px 0 rgba(0, 0, 0, 0.15);
                -moz-box-shadow: 6px 6px 13px 0 rgba(0, 0, 0, 0.15);
            }
        }
    }

}

.contact-form-maindd {
    background: #FFF;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.12);
    margin: 20px 0px;
    padding: 20px;
}

.contact-page-main {
    margin: 20px 0px !important;
}

.contact-submit {
    width: 50% !important;
}

.contact-form {
    // margin-top: -245px;
    margin-bottom: 30px;
    z-index: 1;
    position: relative;

    .contactformaddress-main-div {
        padding: 60px 60px 60px 60px;
        background-color: #FFF;
        border-radius: 5px 5px 5px 5px;
        box-shadow: 0 0 30px 0 rgb(0 0 0 / 12%);
    }
}

.contact-page .contact-iconb .icon-main {
    background: rgba(85, 187, 83, .102);
    border-radius: 50% 50% 50% 50%;
    font-size: 27px;
    height: 60px;
    line-height: 60px;
    width: 60px;
}

.custom-spinners {
    width: 20px !important;
    height: 20px !important;
    border-width: 3px !important;
    color: $primary_color;
    top: 10px;
    margin-top: 19px;
}

.contactform-row-div {
    // margin: 20px 0px !important;
}

.contactformaddress-main-div {
    padding: 10px;
    background-color: #fff;
    border-radius: 5px 5px 5px 5px;

    // padding: 5%;

}

.CONTACTUS-TITLE {
    padding-bottom: 40px;
}

.contactaddress-textarea {
    width: 100%;
    height: 100px;
    border: none !important;
    font-size: 14px;
    border-bottom: 1px solid #cecede !important;
}

.contactformaddress-message {
    color: #000;
    padding-bottom: 20px;
}

.contactaddress-textarea:focus {
    outline: none !important;
    border-bottom: 1px solid #cecede !important;
}


.login-register-btn {
    display: flex;
    justify-content: center;
}

.contactform-wrong {
    display: flex;
    justify-content: end;
}

.contactform-wrong-button {
    cursor: pointer;
}

.contact-form-div {
    padding: 0px !important;
}

.line-side {
    // background: #ef8121;
    height: 2px;
    margin-top: 10px !important;
    width: 82px;
}

.line-content {
    font-size: 14px;
    padding-left: 10px;
    font-weight: 600px;
    font-family: $font-family-2;
}

.heading-contact {
    display: flex;
    justify-content: start;
    padding-bottom: 10px;
}

.ot-heading>span.is_line {}

.ot-heading>span {
    position: relative;
    display: inline-block;
    font-family: $font-family-2;
    font-size: 11px;
    font-weight: 700;
    text-transform: uppercase;
    color: $primary_color;
    margin-bottom: 10px;
}


.custom-spinners1 {
    width: 20px !important;
    height: 20px !important;
    border-width: 3px !important;
    color: $primary_color;
    top: 10px;
    margin-top: 19px;
}

.contact-screen-form {
    // display: flex;
    // justify-content: center;
    // align-items: center;
    margin-top: 25px !important;

}

.modal-header {
    padding: unset !important;
    border-bottom: unset !important;
}

.btn-close {
    --bs-btn-close-focus-shadow: unset !important;
}

.main-head {
    padding-bottom: 10px;
}


// ..........................subscription form
.input-bg-black {
    background: none !important;
    color: #c0c1c2;
}

.subsribe-arrow-icon {
    bottom: 10px;

    cursor: pointer;
    font-size: 25px;
    position: absolute;
    right: 10px;
}

.subscribe-email {
    // color: #cecede !important;
}

.footer-color-content {
    // color: #c0c1c2;
}



.detail_row{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e0e0e0;
    padding: 8px;
}