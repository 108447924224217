.field-holder {
  position: relative;
  margin-bottom: 1.5em;
}

.custom-input {
  border: 0;
  border-radius: 6px;
  outline: none;
  transition: .2s ease-in-out;
  font-size: 12px !important;
  padding: 10px;
  // text-transform: capitalize;
}

.custom-label {
  position: absolute;
  top: 0;
  left: 0;
  display: flex !important;
  align-items: center;
  // color: grey;
  transition: .2s ease-in-out;
  // font-size: $s-size0 !important;
}

.custom-input,
.custom-label {

  width: 100%;
  height: 37px;
  background: #F1F4FE;
}

.custom-textarea {
  height: 70px;
  margin-top: 9px;
}

.custom-input:focus+.custom-label,
.custom-input:valid+.custom-label {
  // color: $primary_color;
  // font-size: $s-size;
  // top: -22px;
  pointer-events: none;
}

.custom-input:focus {
  // border-bottom: 3px solid #7a8473;
}

.custom-input.is-error {
  // border-bottom: 1px solid red;
}

.error-icon {
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: red;
}

.checkout-address-form-title {
  animation: shake 300ms
}

.custom-input.is-error+.custom-label {
  animation: shake 300ms
}

.custom-error-text {
  color: red;
  animation: shake 300ms
}

@keyframes shake {
  25% {
    transform: translateX(4px)
  }

  50% {
    transform: translateX(-4px)
  }

  75% {
    transform: translateX(4px)
  }
}

.error-text {
  position: relative;
  bottom: 20px;
  font-size: 12px;
  color: red;
}


.customer-profile {
  @media only screen and (min-width: 786px) {
    border: 1px solid #eee;
    border-radius: 10px;

  }

  padding: 2rem;
}

.custom-input-disabled {
  pointer-events: none;
  border-bottom: 1px dashed rgb(206, 206, 222);
}

.custom-change-icon {
  position: absolute;
  bottom: 4px;
  right: 4px;
  // color: $primary_color;
  cursor: pointer;
}

.formH {
  display: flex;
  flex-direction: column;
  // text-align: center;
  position: relative;
  padding-bottom: 20px;

  .profileImg {
    border-radius: 50%;
    overflow: hidden;
    width: 90px;
    height: 90px;
    border: 1px solid #ddd;
    padding: 3px;
    background: #FFFFFF;

  }

}

.profileUpdate {
  position: absolute;
  font-size: 14px;
  bottom: 80px;
  display: flex;
  left: 18%;
  background: #FFFFFF;
  border-radius: 50px;
  padding: 2px 3px;
  cursor: pointer;


  // color: #ff9933;

}

.email_phone_update {
  position: absolute;
  font-size: 14px;
  bottom: 0px;
  display: flex;
  left: -20px;
  background: #FFFFFF;
  border-radius: 50px;
  padding: 2px 3px;
}

.profile_settingtitle {
  font-family: Raleway;
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -0.2800000011920929px;
  text-align: left;
  color: #202020;

}

.profile_profiletitle {
  font-family: Raleway;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.1599999964237213px;
  text-align: left;
  color: #000000;
  padding-bottom: 15px;

}

.editprofile {

  border-radius: 50px;
  padding: 0px 3px;
}

.custom-otp-modalsheet {
  height: auto !important;
}

.form-check-input:checked {
  // background-color: $primary_color !important;
  // border-color:$primary_color !important;
}

.form-check-input:focus {
  border-color: black !important;
  box-shadow: none !important;
}

input[name="address"].form-check-input:checked {
  background-color: blue !important;
  border-color: blue !important;
}

.form-control:focus {
  // border-color:$border_color !important;
  outline: 0;
  box-shadow: none !important;
}

.password-inputbox {
  position: relative;
}

.passwordeyeicon {
  position: absolute;
  top: 2px;
  right: 23px;
  cursor: pointer;
}
.errorotp{
  font-size: 10px;
  color: red;
}

.phone-number-dropdown {
  width: 110px;
  font-size: 10px;
  margin-right: 3px;

}

.phone-number-dropdown select {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 12px;
  background: #F1F4FE;
  height: 37px;
  cursor: pointer;
}

.phone-number-dropdown label {
  font-size: 10px;
  color: #333;
  margin-bottom: 5px;
  display: block;
}
