.mainFilter {
    // background: #fbf7f7;
   
   
    overflow-y: auto;
    padding: 5px;
    position: relative;
 

    @media only screen and (min-width: 991px) {
        max-height: 80vh !important;
        position: sticky;
        margin-bottom: 50px;
        position: -webkit-sticky;
        top: 114px;
    }
    @media only screen and (max-width: 991px) {
        // padding-top: 50px;
    }

    .main-filtw-hedER {
        display: flex;
        justify-content: space-between !important;
        align-items: center;
        padding: 10px 10px 0px;

        .filters-heading {
            font-weight: 500;
            color: $black;
            font-size: $s-size;
        }

        .filter-products-list {
            // display: flex;

            // align-items: center;
        }


        .filters-clear-all {
            font-size: 14px;
            cursor: pointer;
            color: #323232;
        }
    }

    .menuShow {
        align-items: baseline;
        background-color: transparent;
        border: none;
        display: flex;
        justify-content: space-between;
        margin-top: 5px;
        width: 100%;
        margin-bottom: -15px;

        .filterTitle {
            color: #000000;
            font-size: 14px;
            font-weight: 700;
            text-transform: capitalize;
            padding-top: -5px;
            //styleName: Heading/20px - Bold;
            font-family: Be Vietnam Pro;



        }
    }
    .filtertop_name{
        display: flex;
        font-size: 14px;
        font-weight: 700;
        padding-top: 10px;
    }

    .sub-sub-items {
        padding-left: 20px;
    }
.list{
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    font-weight: 400;
}



    .catgeories {
        justify-content: start;
        display: flex;

    }

    .price {
        .priceTitle {
            color: #333;
            font-size: 14px;
            font-weight: 700;

            text-transform: capitalize;
            padding-left: 10px;
            padding-top: -5px;
        }

        ul {
            list-style-type: none;

            display: block;
            padding: unset;
            padding-left: 10px;
            margin-top: -10px;
            font-size: 15px;

            li {
                a {
                    text-decoration: none;
                    color: $paragraph-color;
                }
            }
        }

        .priceRange {
            flex-direction: row;
            width: max-content;
            padding-left: 10px;
            margin-top: -10px;
            padding-right: 10px;

            input {
                width: 50px;
                margin-right: 10px;
                border-radius: 5px;
            }

            button {
                border-radius: 5px;
            }
        }
    }

}

.mainFilter::-webkit-scrollbar {
    width: 4px;
    cursor: pointer;
}

/* Track */
.mainFilter::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.mainFilter::-webkit-scrollbar-thumb {
    background: #c1c1c1;
}

.toptitle {
    margin-bottom: 5px;
}

.filterItems {

    // margin-top: 10px;
    .search {
        padding: 0px 30px 10px 10px;

        Input {
            padding: 5px;
            font-size: 11px;
            width: 100%;
            background: #F5F5F5;
            border: 1px solid #eee;
            outline: none;
            border-radius: 5px;
        }
    }

    .textScroll {
        max-height: 150px;
        overflow-y: scroll;
        width: 100%;

    }

    .textScroll::-webkit-scrollbar {
        width: 2.5px;
    }


    .textScroll::-webkit-scrollbar-track {
        box-shadow: $black;
        border-radius: 10px;
    }


    .textScroll::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 15px #333;
    }

    ul {
        width: 100%;
        padding: unset;
        padding-left: 10px;
        list-style-type: none;
        font-size: 13px;
        border: none;
        display: flex;
        flex-direction: column;

        li {
            display: inline;

            .list {
                font-size: 13px;
                display: contents;

                input {
                    margin-right: 10px;
                }

                a {
                    text-decoration: none;
                    color: #212529;
                }



            }

            .list-sub {
                display: flex;
                flex: row;
                justify-content: space-between;

                .sub-icon-expand {
                    padding-right: 10px;
                    cursor: pointer;
                }
            }
        }

    }
}
.sub-icon-expand{
    cursor: pointer;
}
.custom-badge-filters {
    background-color: $primary_color !important;
    float: right;
    font-size: 9px !important;
    margin-top: 4px !important;
}

.filter-summary-filterList {
    display: -webkit-box;
    display: -ms-flexbox;
    padding-top: 10px;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
    margin: 0;


    .filter-summary-filter {
        position: relative;
        background-color: #fff;
        text-transform: capitalize;
        color: #3e4152;
        cursor: default;
        font-size: 12px;
        cursor: pointer;
        padding: 5px;
        -webkit-transition: all .2s ease-out;
        transition: all .2s ease-out;
        border-radius: 5px;
        margin: 10px 5px 5px 0px;
        border: 1px solid #d4d5d9;

        .filter-summary-parent {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .filter-summary-removeIcon {
                padding: 1px 0px 0px 5px;
                margin-top: -2px
            }

            .filter-details-data {
                font-size: 12px;
            }
        }
    }

}

.filter-summary-rightside {
    @media only screen and (min-width: 1200px) {
        width: 20% !important;
    }

    @media only screen and (max-width: 1200px) {
        width: 25% !important;
    }
}


$menu_WIDTH: 150px;

.menu {
    display: block;
    position: relative;
    cursor: pointer;
}

.menu-title {
    padding: 10px;
    color: black;
    border-top: 1px solid #eee;
    border-left: 1px solid #eee;
    border-right: 1px solid #eee;
    font-size: 14px;

}

.active-title {
    // color: $primary_color;
    font-weight: 500;
}

.active-sortby-bg {
    background: $primary_color !important;
    color: $white !important;
}

.menu-title:before {
    content: "";
    display: block;
    height: 0;
    // border-top: 5px solid #9dc852;
    border-left: ($menu_WIDTH / 2) solid transparent;
    border-right: ($menu_WIDTH / 2) solid transparent;
    border-bottom: 0 solid #dddddd;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 101;
    transition:
        0.2s 0.2s border-top ease-out,
        0.3s border-top-color;
}



.menu:hover>.menu-title:before {
    border-top-width: 0;
    transition:
        0.2s border-top-width ease-in,
        0.3s border-top-color;
}

.menu-title:after {
    content: "";
    display: block;
    height: 0;
    border-left: ($menu_WIDTH / 2) solid transparent;
    border-right: ($menu_WIDTH / 2) solid transparent;
    // border-bottom: 0 solid #ebebeb;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 101;
    transition: 0.2s border-bottom ease-in;
}



.menu-dropdown {
    min-width: 100%;
    position: absolute;
    background: $white;
    font-size: 14px;
    z-index: 100;
    border-left: 1px solid #eee;
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
    // box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.11);
    transition:
        0.5s padding,
        0.5s background;
}

.menu-dropdown:after {
    content: "";
    display: block;
    height: 0;
    // border-top: 5px solid #ebebeb;
    border-left: ($menu_WIDTH / 2) solid transparent;
    border-right: ($menu_WIDTH / 2) solid transparent;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 101;
    transition: 0.5s border-top;
}

.menu:not(:hover)>.menu-dropdown {
    padding: 0px 0;
    background: $white;
    z-index: 99;
}

.menu:not(:hover)>.menu-dropdown:after {
    // border-top-color: #dddddd;
}

.menu:not(:hover)>.menu-title:after {
    border-bottom-color: #dddddd;
}

.menu-dropdown>* {
    overflow: hidden;
    height: 33px;
    padding: 6px 10px;
    // background: rgba(0, 0, 0, 0);
    white-space: nowrap;
    transition:
        0.5s height cubic-bezier(.73, .32, .34, 1.5),
        0.5s padding cubic-bezier(.73, .32, .34, 1.5),
        0.5s margin cubic-bezier(.73, .32, .34, 1.5),
        0.5s 0.2s color,
        0.2s background-color;
}

.menu-dropdown>*:hover {
    background: #f4f4f5;
}

.menu:not(:hover)>.menu-dropdown>* {
    visibility: hidden;
    height: 0;
    padding-top: 0;
    padding-bottom: 0;
    margin: 0;
    color: rgba(25, 25, 25, 0);
    transition:
        0.5s 0.1s height,
        0.5s 0.1s padding,
        0.5s 0.1s margin,
        0.3s color,
        0.6s visibility;
    z-index: 99;
}

.filter-products-list-mobile {
    display: flex;
}

.filters-products {
    padding: 5px 0px 5px 10px;
    font-weight: 500;
    color: $black;
    font-size: $xs-size;
}

.filtercolor {
    width: 20px;
    height: 20px;
}

.parent {
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: wrap;
}

.filter_category {
    background:  #C23A97;
    //styleName: Body Text/16px - Regular;
    font-family: Be Vietnam Pro;
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin: 10px;
    color: #fff;
    border-radius: 50px;
    padding: 5px 10px;

}

.filetrcategory-main {
    // display: flex;
    align-items: center;
    text-align: center;
    // overflow-x: scroll;
    // overflow-y: hidden;
    // white-space: nowrap;
    width: 100%;
}

.filter_category {
    padding: 5px 10px;
}

.filetrcat-main::-webkit-scrollbar {
    display: none;
}
.pricerangebtn{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.gobtn{
    border: 1px solid black;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
}

.filtercontent {
    //styleName: Body Text/16px - Regular;
    font-family: Be Vietnam Pro;
    font-size: 12px;
    font-weight: 400;
    cursor: pointer;
    // line-height: 14px;
    text-align: left;
    color: #000000;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

}

.filterrighticon {
    font-size: 15px;
    color: #3786FB;
    margin-right: 10px;

}



.filterprice_title {
    color: #000000;
    font-size: 14px;
    font-weight: 700;
    padding-left: 10px;
    text-transform: capitalize;
    padding-top: 10px;
    font-family: Be Vietnam Pro;
}
.sheet {
    position: fixed;
    bottom: env(safe-area-inset-bottom, 0);
    left: 0;
    width: 100%;
    max-height: calc(100vh - env(safe-area-inset-top, 0));
    background-color: white;
    z-index: 1000;
    overflow-y: auto;
    box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.2);
  }