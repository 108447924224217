.succussMsgBody {
  display: flex;
  flex-direction: row;
  font-size: 30px;
  font-weight: 600;
  margin-top: 20px;

  .successIconHead {
    padding-right: 10px;

    .successIcon {
      width: 40px;
    }
  }
}

.order-success-title {
  padding-left: 60px;
  padding-top: 5px;
  font-size: $s-size0;
}

.order-success-title1 {
  font-size: $s-size;
  font-weight: 700;
  text-decoration: underline;
  color: $primary_color;
}

.successOrderProducts {
  border-radius: 5px;
  margin-bottom: 30px;
  margin-top: 30px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, .08);

  .successItems {
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid #cecede;
    display: flex;
    overflow: scroll;

    .successImg {
      width: 80px;
      height: 80px;
      object-fit: contain;
    }

    .successProduct {
      padding-top: 5px;
      font-size: 16px;
      font-weight: 500;
    }
  }
}

.successOrdersButton {
  padding: 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  color: $primary_color;

  .successOrdersButtonIcon {
    padding-left: 10px;
  }
}

@media only screen and (max-width:320px) {
  .succussMsgBody {

    font-size: 25px;
  }
}

@media only screen and (min-width:320px) {
  .succussMsgBody {

    font-size: 25px;
  }
}

@media only screen and (min-width:568px) {
  .succussMsgBody {

    font-size: 25px;
  }
}

@media only screen and (min-width:786px) {
  .succussMsgBody {

    font-size: 30px;
  }
}

@media only screen and (min-width:991px) {
  .succussMsgBody {

    font-size: 35px;
  }
}

.EmojiHead {
  padding-top: 15px;
  width: 70%;

  .EmojiHead-subhead {
    font-size: 20px;
    font-weight: 500;
  }

  .EmojiHead-Smilys {
    display: flex;

    justify-content: space-between;
    text-align: center;
    padding-top: 10px;

    .EmojiHead-Smilys-sub {


      .EmojiHead-Smilys-sub-smily {
        font-size: 30px;
        color: lightgrey;
        padding-bottom: 10px;
      }

      .EmojiHead-Smilys-sub-content {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
}

.EmojiHead-Smilys-sub-smily:active {
  color: ff9933;
}

@media only screen and (max-width:520px) {
  .EmojiHead {
    width: 100%;

    .EmojiHead-Smilys {
      .EmojiHead-Smilys-sub {
        .EmojiHead-Smilys-sub-smil {
          font-size: 10px;
        }

        .EmojiHead-Smilys-sub-content {
          font-size: 12px;
        }
      }
    }
  }
}

.ordersuccess-btn {
  margin-top: 10px;
}