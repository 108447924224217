.custom-margin-top {
    @media only screen and (min-width: 768px) {
        margin-top: 30px !important;
    }
}

.accounts {
    padding: 20px 10px 10px 10px;

    @media only screen and (max-width: 768px) {
        // height: 100vh;
        margin-bottom: 50px;
        overflow-y: scroll;
    }

    @media only screen and (min-width: 768px) {
        border: 1px solid #eee;
        display: inline-block;
        border-radius: 10px;
        width: 100%;

    }

    .accounts1 {
        display: flex;

        float: left;
        width: 100%;
        padding: 8px 0px 25px 0px;
        border-bottom: 1px solid #cecede;

        .my-account-login-btn {
            background: $white;
            border: 1px solid $primary_color;
            border-radius: 5px;
            color: $black;
            font-size: $s-size0;
            font-weight: 600;
            text-align: center;
            cursor: pointer;

            margin-left: 20px;
            padding: 10px;
            width: 100%;
        }

        .icon1 {
            font-size: 3rem;
            // color:$primary_color;
            bottom: 10px;
            display: flex;
            align-items: center;

            .my-account-img {
                width: 50px;
                height: 50px;
                border: 1px solid #eee;
                // border-radius: 31px;
                object-fit: contain;
            }
        }

        .content1 {
            padding-left: 14px;
            flex-grow: 1;
            display: inline;


            text-overflow: ellipsis;
            overflow: hidden;

            .content1Head {
                // font-size: 14px;
                // font-weight: bold;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            .content1Mail {
                // font-size: 12px;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            .content1Contact {
                // font-size: 12px;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }

        .edit {
            // font-size: 12px;
            font-weight: bold;
            // color: $primary_color;


        }
    }

    .accountList {
        display: flex;
        align-items: center;

        float: left;
        width: 100%;
        padding: 15px 0px;
        border-bottom: 1px solid #cecede;

        .iconList {
            // color: $primary_color;



        }

        .contentList {

            // font-size: 14px;
            flex-grow: 1;
            padding-left: 14px
        }

        .arrowList {
            font-size: 14px;
            color: $primary_color;
        }
    }

    .subscribe-button-mobile {
        background: $primary_color;
        color: #fff;
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 10px;
        border-radius: 5px;
    }

    .signOut {
        margin-top: 10px;

        .signOutButton {
            width: 100%;
            padding: 14px 0px;
            border-radius: 24px;
            border: none;
            background-color: $primary_color;
            color: #fff;
            font-weight: 400;
        }
    }

}

.my-custom-modal {
    .modal-dialog {
        max-width: 400px;
    }

}

.custom-modal-component {
    @media only screen and (max-width: 768px) {
        padding: 20px;
    }

    .custom-modal-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 25px;

        .modal-header-title {
            font-size: $s-size0;
            font-weight: 700
        }
    }

    .send_otp_btn {
        margin-left: unset;

        .product-detail-button {
            margin: unset;
        }
    }
}

.SubscriptionStatus-main-head {

    .SubscriptionStatus-account {
        border: 1px solid #cecede;
        padding: unset !important;
        margin-bottom: 10px;
        border-radius: 5px;

        .SubscriptionStatus-account-row-head {
            //    font-size: 16px;
            //    font-weight: 600;
            padding: 5px 10px;

            // background: $primary_color;
            // color: $white;
        }

        .SubscriptionStatus-account-row-col {

            .SubscriptionStatus-account-list {
                //    font-size: 14px;
                //    font-weight: 600;
                padding: 5px 0px;
            }

            .SubscriptionStatus-account-list-sub {
                //    font-size:12px ;
                //    font-weight: 500;
                //    color:$primary_color ;
                padding-bottom: 5px;

            }
        }
    }
}

.account-type-sub {
    text-transform: uppercase;
}

.address-error {
    color: red;
}

.myaddress_account {
    border-width: 1px;
    box-sizing: border-box;
    border-color: #C7C7C7;
    box-shadow: 0 2px 1px 0 rgba(0, 0, 0, .16);
    border-style: solid;
    border-radius: 5px;
    height: 90%;
    padding: 15px;
    margin: 10px 0px;
}

.addresname0 {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
}

.addresname1 {
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
}

.addrestitles {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
}

.editandremove {
    display: flex;
    flex-direction: row;
    padding-top: 30px;
    font-size: 14px;
    font-weight: 500;
    // color: #007185;
}

.addresstitle_account {
    border-width: 2px;
    box-sizing: border-box;
    border-color: #C7C7C7;
    border-style: dashed;
    border-radius: 5px;
    padding: 15px;
    margin: 10px 0px;
    cursor: pointer;
    height: 91%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.add_address_account {
    font-size: 60px;
    color: #C7C7C7;
}

.add_addrss_title_account {
    font-size: 24px;
    font-weight: 700;
    color: #565959;
}

.cursorpointer {
    cursor: pointer;
}

.accout_breadcomp1 {
    font-size: 14px;
    font-weight: 500;
    color: #565959;
    cursor: pointer;
}


.accout_breadcomp2 {
    font-size: 14px;
    font-weight: 500;


}
.Youraddress_title{
    
}
.custom-modal-closeicon{
    cursor: pointer;
}