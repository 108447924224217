.progressbar {
    width: 220px;
    height: 18px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    border-radius: 3px;
    background-color: $border_color;
    overflow: hidden;
    margin-bottom: 15px;
}

.bar {
    /* Size */
    width: 0%;

    /* Style */
    background: rgb(255, 174, 105);
    background: linear-gradient(180deg,
            rgba(255, 174, 105, 1) 0%,
            rgba(255, 173, 102, 1) 32%,
            rgba(255, 132, 25, 1) 100%);
}

.progressbar-container {
    display: flex;
    flex-direction: row;
}

.progressbar-text-container {
    width: 30px;
    margin-left: 15px;
    display: flex;
    flex-direction: row;
    font-weight: 400;
    color: $primary_color;
}

.progressbar-startext {
    width: 55px;
    display: flex;
    font-weight: 400;
    color: $primary_color;
    font-size: 14px;
}

.progress {
    height: 10px !important;
   
}

.progressbarclass {
    background: rgb(5, 61, 5) !important;
    position: relative;
}
.progress_circles{
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.dgdgdg{
    top: 0%;
    width: 30px;
    z-index: 99;
    position: absolute;
    border-radius: 50px;
    height: 30px;
}
.active-circle {
    position: absolute;
    // position: absolute;
    top: 0%;
    width: 30px;
    z-index: 99;
    border-radius: 50px;
    height: 30px;
}
.progress-icon{
    position: absolute;
    background: rgb(204, 204, 204);
    border-radius: 50px;
    top: 10px;
    transform: translateX(-50%);
}


.canclebar{


    .progress-container {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: space-between;
        width: 100%; /* Full-width container */
        max-width: 800px; /* Optional: Limit maximum width */
        margin: 20px auto; /* Centered layout */
      }
      
      .step1 {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        position: absolute;
        left: -11px;
        top: -8px;
        
      }
      .step2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        position: absolute;
        top: -11px;
        right: -24px;
    
      }
      
      .circle {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background-color: red; /* Red background */
        border: 3px solid red; /* Red border */
        margin-bottom: 8px;
      }
      
      .label {
        font-size: 14px;
        font-weight: bold;
        color: #333;
      }
      
      .progress-bar-container {
        flex-grow: 1;
        height: 6px;
        background-color: #f5f5f5; /* Light gray track */
        margin: 0 10px;
        border-radius: 3px;
        overflow: hidden;
        position: relative; /* Important for positioning absolute elements */
      }
      
      .progress-bar {
        width: 100%; /* Full progress */
        background-color: red; /* Red fill */
        height: 100%;
        transition: width 0.4s ease-in-out; /* Smooth animation */
      }
      
    }