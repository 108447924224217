.myorder-main-div {
    // border: 1px solid $border_color;
    // border-radius: 8px;
    // margin-top: 20px !important;
    // margin-bottom: 80px !important;
    margin: 10px 10px 10px 10px !important;
    // font-size: 14px;
    // font-weight: 400;
    // padding: 14px 18px;
    // font-family: $font-family-2;
    padding: unset;
    margin: unset;

    .order-main-title-div {
        // border-bottom: 1px solid $border_color;
        // border: 1px solid $border_color;
        padding: unset;
        margin: unset;
        // padding: 14px;
        // background: #F0F2F2;
        border-radius: 8px 8px 0px 0px;
    }

    .AAAA {
        display: flex;
        flex-direction: row;
    }

    .order-main-title-sub-div {
        padding-left: 10px;
       
    }
    // .order-data-title{
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //     text-align: center;
    // }
    .order-detail-subtitle{
        // color: $primary_color;
        // font-size: 12px;
        // font-weight: 700;
    }
    .order-coloum {
        padding: unset;
        margin: unset;
    }

    .myorder-sub-div {
        padding: unset;
        margin: unset;

        .order-id-div {
            display: flex;
            justify-content: end;
            text-align: end;
        }
        @media only screen and (max-width: 991px) {
            .order-id-div {
                display: flex;
                justify-content:start ;
                text-align: start;
                padding-top: 10px;
            }
          }

        .order-titles {
            display: flex;
            flex-direction: column;

            .order-title-sub {
                display: flex;
                flex-direction: row;

                .view-order-title {
                    padding-right: 10px;
                   
                    text-transform: capitalize;
                }

                .invoice-title {
                    padding-left: 10px;
                    border-left: 1px solid $border_color;
                }
            }
        }
    }


    .order-data-fulldetail {
        // border-bottom: 1px solid $border_color;
        border-top: 1px solid $border_color;
        padding: unset;
        margin: unset;
        padding: 14px;

        .orderpage-detils {
            // padding: 12px;

            .order-delivered-date {
                // font-size: 20px;
                // font-weight: 600;
                display: flex;

            }
             .delevered-date{
                padding-left: 10px;
             }
            .order-full-details {
                display: flex;
                flex-direction: row;
                padding-top: 10px;

                .order-image {
                    // aspect-ratio: 3/4;
                    // height: 70px;
                    // width: auto;

                }

                .order-image-details {
                    display: flex;
                    flex-direction: column;
                    padding-left: 10px;
                    padding-right: 20px;

                    .order-image-title {
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        /* Number of lines to show */
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    .order-return-details {
                        font-size: 12px;
                        padding: 2px 0px;
                    }

                }

                .order-right-side {
                    display: flex !important;
                    flex-direction: column !important;
                }

                

            }


        }
        .order-buttons-main {
            display: flex;
            flex-direction: row;
            margin-top: 10px;
           text-transform: capitalize;
            .order-button-buy {
                // background: $primary_color;
                border: none;
                // border-radius: 5px;
                // color: #fff;
                cursor: pointer;
                // font-size: 12px;
                // font-weight: 500;
                margin-right: 5px;
                padding: 5px 8px;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;

                .buy-image {
                    width: 20px;
                    color: #fff !important;


                }

                .buy-icon {
                    font-size: 16px;
                    padding-right: 3px;
                }
            }

            .order-button-buy:hover {
                opacity: 0.9;
            }

            .order-button-view {
                // border-radius: 5px;
                // color: $primary_color;
                cursor: pointer;
                // font-size: 12px;
                // font-weight: 600;
                // margin-right: 15px;
                padding: 5px 8px;
                text-align: center;
                // border: 1px solid $border_color;
            }

            .order-button-view:hover {
                border: 1px solid $primary_color;
            }

        }
       

        .order-right-div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
           .aaaaa{
            width: 100%;
           }
            .order-button-right {
                text-transform: capitalize;
                border-radius: 5px;
                color: $primary_color;
                width: 100%;
                cursor: pointer;
                font-size: 14px;
                font-weight: 600;
                // margin-right: 15px;
                // width: fit-content;
                padding: 7px 10px;
                text-align: center;
                border: 1px solid $border_color;
                margin: 2px 5px;
            }

            .order-button-right:hover {
                border: 1px solid $primary_color;
            }

        }

    }
    
    .bottom {
        background: none !important;
        padding: unset !important;
        margin: unset !important;
        padding: 14px !important;
    }
}

// @media only screen and (min-width: 280px) {
//     .order-buttons-main{
//         display:flex !important;
//         flex-direction: row !important;
//         margin-top: 10px;
//        text-transform: capitalize;
 
//         .order-button-buy{
//             width: 50% !important;
//             margin: 5px 0px;
//           }
//           .order-button-view{
//             width: 50% !important;
//             margin: 5px 0px;
//           }
//     }
 
//   }
@media only screen and (max-width: 576px) {
    .order-buttons-main{
        display:flex !important;
        flex-direction: row !important;
        justify-content: center;
        margin-top: 10px;
       text-transform: capitalize;
 
        .order-button-buy{
            // width: 50% !important;
            margin: 5px 0px;
            padding: 5px 20px !important;
          }
          .order-button-view{
            // width: 50% !important;
            margin: 5px 0px;
            padding: 5px 20px !important;
          }
    }
 
  }
  @media only screen and (min-width: 576px) {
    .order-buttons-main{
        display:flex !important;
        flex-direction: row !important;
        justify-content: start;
        margin-top: 10px;
       text-transform: capitalize;
 
        .order-button-buy{
            // width: 50% !important;
            margin: 5px 0px;
          }
          .order-button-view{
            // width: 50% !important;
            margin: 5px 0px;
          }
    }
 
  }

//   .myorder-bottom{
//     padding-bottom: 50px;
//     height: 100vh;
//     overflow-y: scroll;
//   }
  .wishlistdata_data{
    // height: fit-content !important;
    // overflow-y: scroll;
}