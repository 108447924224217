.product-variant {
    .product-variant-heading {
        display: flex;
        justify-content: space-between;

        .selected-variant-text {
            font-weight: 700;
            color: $primary_color;
        }

        .selected-image-variant {
            img {
                height: 40px;
                margin-right: 10px;
            }
        }

        .filterTitle {
            margin-right: 10px;
            // font-size: 18px;
            // font-family: $font-family-2;
            // font-weight: 500;
        }
    }

    .product-variant-list {
        margin-left: unset;
        cursor: pointer;
        display: flex;
        flex-wrap: wrap;

        .product-varinat-item-active {
            // background: $primary_color !important;
            // color: $white;
        }

        .product-varinat-item-inactive {
            border-color: #f0f0f0 !important;
            color: #f0f0f0;
        }

        .product-varinat-item {
            border: 1px solid $gray-10;
            margin-right: 5px;
            margin-bottom: 5px;
            border-radius: 5px;

            img {
                height: 75px;
                width: 100%;
                object-fit: contain;
            }


        }

        .product-varinat-item-text {
            border: 1px solid #eee;
            margin-right: 5px;
            // margin-bottom: 5px;
            border-radius: 5px;
            text-align: center;
            width: fit-content;
            padding: 2px 10px 2px 10px;
            margin-bottom: 5px;
        }

    }
}

@media screen and (min-width: 768px) and (max-width:991px) {
    .product-variant-list .product-varinat-item {
        width: 10%;
    }
}


@media screen and (min-width: 1200px) {
    .product-variant-list .product-varinat-item {
        width: 12%;
    }
}
.varient-list-data{
    // margin-bottom: 1px !important;
    // font-size: 14px !important;
    line-height :1 !important;
    margin: 5px 0px !important;
    // font-weight: 400 !important;

}
// h5{
//     margin: 1px !important;
// }


// .react-tooltip {
//     border: 1px solid #D5D9D9 !important;
//     border-radius: 5px !important;
//     padding: unset !important;
//     width: 192px !important;
//     box-shadow: 0 3px 16px 0 rgba(0, 0, 0, .11) !important;
//     background:none !important;
// }

// .tooltip-header {
//     // border-bottom: 1px solid;
//     // border-color: #D5D9D9;
//     background-color: #F7FAFA;
//     padding: 12px;
//     word-wrap: break-word;
//     display: block;
//     color: #0F1111 !important;
//     font-size: 14px !important;
//     font-weight: 700 !important;
// }

// .react-tooltip-arrow{
//     background: #F7FAFA !important;
//     border-bottom: 1px solid #D5D9D9 !important;
//     border-right: 1px solid #D5D9D9 !important;
// }
