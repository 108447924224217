/* Body */
$body-background: linear-gradient(to left, #a55eea, #45aaf2);
$radius : 20px;
$table-padding : 20px;
$break-small: 320px;
$break-large: 1200px;

$primary: #4b7bec;
$echo: #f7b731;
$pro: #26de81;
$business: #a55eea;

@import '../1\ -\ settings/colors';

@mixin transition($time, $property) {
    -webkit-transition: $time $property ease;
    -ms-transition: $time $property ease;
    transition: $time $property ease;
}

/* Card Themes - Start */

.basic {
    .pricing-header {
        // background-color: $primary_color;
    }

    .price-circle {
        // border: 10px solid $primary_color;
        transition: all .4s;
    }

    &:hover {
        .price-circle {
            border-width: 5px;
        }
    }

    .buy-now {
        &:hover {
            background-image: none !important;
            background-color: $primary_color !important;
            box-shadow: 1px 3px 5px rgba(0, 0, 0, .4);
        }
    }
}

.echo {
    .pricing-header {
        background-color: $echo;
    }

    .price-circle {
        border: 10px solid $echo;
        transition: all .4s;
    }

    &:hover {
        .price-circle {
            border-width: 5px;
        }
    }

    .buy-now {
        &:hover {
            background-image: none !important;
            background-color: $echo !important;
            box-shadow: 1px 3px 5px rgba(0, 0, 0, .4);
        }
    }
}

.pro {
    .pricing-header {
        background-color: $pro;
    }

    .price-circle {
        border: 10px solid $pro;
        transition: all .4s;
    }

    &:hover {
        .price-circle {
            border-width: 5px;
        }
    }

    .buy-now {
        &:hover {
            background-image: none !important;
            background-color: $pro !important;
            box-shadow: 1px 3px 5px rgba(0, 0, 0, .4);
        }
    }
}

.business {
    .pricing-header {
        background-color: $business;
    }

    .price-circle {
        border: 10px solid $business;
        transition: all .4s;
    }

    &:hover {
        .price-circle {
            border-width: 5px;
        }
    }

    .buy-now {
        &:hover {
            background-image: none !important;
            background-color: $business !important;
            box-shadow: 1px 3px 5px rgba(0, 0, 0, .4);
        }
    }
}

/* Card Themes - End */



.containerq {
    max-width: 1200px;
    height: auto;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
}

.column {
    flex: 25%;
    padding: 10px;
    width: calc(33.3% - 30px);
    box-sizing: border-box;

    @media screen and (max-width: 980px) {
        flex: 50%;
        display: block;
        padding:10px 20px 10px 20px
    }

    @media screen and (max-width: 700px) {
        flex: 100%;
        display: block;
    }
}

.pricing-card {
    @include transition(0.4s, background-color);
    height: 100%;
    // background-color: white;
    // border: 1px solid #eee;
    // border-radius: 5px;
    position: relative;
    cursor: pointer;
    transition: all .4s;

    @media only screen and (min-width: 991px) {
        &:hover {
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
            transform: scale(1.05);
        }

    }


    .popular {
        position: absolute;
        top: 0;
        right: 5%;
        width: auto;
        padding: 10px;
        border-bottom-left-radius: $radius;
        border-bottom-right-radius: $radius;
        background-color: #eb3b5a;
        color: white;
        font-size: 12px;
        z-index: 1;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    }

    .badge-box {
        padding: 0 40px;
        margin-top: 70px;

        span {
            display: inline-block;
            border: 1px solid $primary_color;
            padding: 4px 12px;
            border-radius: 25px;
            overflow: hidden;
            color:$primary_color;
        }
    }

    .pricing-header {
        width: 100%;
        // height: 150px;
        position: relative;
        // border-radius: 5px;
        /*border-bottom: 20px solid $primary;*/
        /*
    border-radius: $radius $radius 150px 150px;
    -webkit-border-radius: $radius $radius 150px 150px;
    -moz-border-radius: $radius $radius 150px 150px;
    */

        .plan-title {
            // font-size: 24px;
            // color: white;
            position: relative;
            top: 25%;
        }

        .price-circle {
            width: calc(33.3% - 30px);
            // width: 120px;
            // height: 120px;
            border-radius: 100%;
            left: calc(50% - 60px);
            top: 60%;
            // background-color: white;
            position: absolute;

            //box-shadow: 1px 3px 5px rgba(0, 0, 0, .4);
            .info {
                display: block;
                font-size: 12px;
                font-weight: bold;
                color: gray;
            }

            .price-title {
                display: block;
                font-size: 28px;
                padding: 28px 0 0;
                font-weight: bold;

                small {
                    font-size: 18px;
                }
            }
        }

        h2 {
            position: relative;
            top: 40%;
            color: #fff;
        }
    }

    ul {
        margin: 10px 0 0 0;
        padding: 0;

        li {
            list-style-type: none;
            display: block;
            padding: 15px 0 15px 0;
            margin: 0;
            border-bottom: 1px solid #f2f2f2;
        }
    }

    .buy-button-box {
        width: 100%;
        float: left;
        margin-top: 20px;
        margin-bottom: 20px;
        .buy-now {
            text-decoration: none;
            // color: white;
            padding: 10px 30px;
            // border-radius: 5px;
            // background:$primary_color;
            margin-top: 20px;
            
        }
    }
}



.swiper-subscription {
    width: 300px;
    height: auto;

    .swiper-slide-shadow {
        background: none;
    }
}

// .swiper-slide {
//     display: flex;
//     align-items: center;
//     justify-content: center;
// }

.containerq-mobile {
    height: 100%;
    position: relative;
    overflow: hidden;
}

.subscription-modal{
    height: 520px;
}

.modal-subscription{
    .pricing-card{
        height: 400px;
        .pricing-header{
            height: 120px;
            .plan-title{
                font-size: 20px;
            }
            .price-circle {
               width: 100px;
               height: 100px;
        
        .price-title{
            padding: 10px 0px 0px;
            font-size: 20px;
        }
        
    
  
    }
    }
    ul{
        li{
            padding: 7px 0px 7px 0px;
        }
    }
    }

}

.subscription-cancel{
    display: flex;
    justify-content: end;
    .subscription-cancel-icon{
        width: 15px;
        height: 15px;

    }
}

/* Sunscription component 2 start */
.pricing-table-section{
    background-color: #000;
    .space-90 {
        width: 100%;
        height: 90px;
    }
    .ot-heading > span {
        position: relative;
        display: inline-block;
        text-transform: uppercase;
        color: $primary_color;
        margin-bottom: 10px;
        font-weight: 700;
    }
    .ot-heading > span.is_highlight {
        background: rgba(254, 132, 35, 0.1);
        padding: 2px 10px;
        border: 1px solid $primary_color;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        border-radius: 15px;
    }
    .ot-heading .main-head {
        margin-bottom: 0;
    }
    .space-20 {
        width: 100%;
        height: 20px;
    }
    .space-3 {
        width: 100%;
        height: 3px;
    }
    p {
        margin: 0 0 20px;
    }
    // .space-30 {
    //     padding-top: 30px;
    // }
    .ot-switcher {
        text-align: center;
        span{
            font-size: 14px;
            font-weight: 600;
            vertical-align: middle;
        }
        .switch {
            position: relative;
            display: inline-block;
            width: 66px;
            height: 34px;
            margin: 0 15px;
            input {
                // opacity: 0;
                // width: 0;
                // height: 0;
            }
        }
        .slider .round {
            border-radius: 34px;
        }
        // .slider {
        //     position: absolute;
        //     cursor: pointer;
        //     top: 0;
        //     left: 0;
        //     right: 0;
        //     bottom: 0;
        //     background-color: rgba(0, 0, 0, 0.2);
        //     -webkit-transition: 0.4s;
        //     transition: 0.4s;
        // }
    }
    .space-60{
        width: 100%;
        height: 60px;
    }
    .ot-pricing-table:hover {
        -webkit-transform: translateY(-10px);
        -ms-transform: translateY(-10px);
        transform: translateY(-10px);
    }
    .ot-pricing-table{
        position: relative;
        text-align: center;
        overflow: hidden;
        transition: all 0.3s linear;
        -webkit-transition: all 0.3s linear;
        box-shadow: 8px 8px 30px 0 rgba(0, 0, 0, 0.12);
        -webkit-box-shadow: 8px 8px 30px 0 rgba(0, 0, 0, 0.12);
        border-radius: 5px;
        -webkit-border-radius: 5px;
        .inner-table {
            padding: 30px 40px 40px;
            background: #fff;
            .title-table {
                margin-bottom: 35px;
                font-size: 20px;
                span{
                    display: inline-block;
                    padding: 7px 11px;
                    line-height: 1;
                    font-size: 13px;
                    margin-bottom: 0;
                    text-transform: uppercase;
                    color: #fff;
                    background: #262832;
                    -webkit-border-radius: 14px;
                    -moz-border-radius: 14px;
                    border-radius: 14px;
                }
            }
            sup {
                position: absolute;
                top: 15px;
                left: 8px;
                font-size: 24px;
            }
            .amt{
                font-size: 60px;
                font-weight: 400;
                line-height: 1;
                position: relative;
                display: inline-block;
                padding-left: 27px;
                top: -1px;
                color: $primary_color;
            }
            p {
                display: inline-block;
                position: relative;
                color: #959595;
                bottom: -9px;
                padding-left: 15px;
                margin-bottom: 40px;
            }
            p:before {
                position: absolute;
                content: "";
                top: -15px;
                left: 5px;
                width: 1px;
                height: 49px;
                background: #959595;
                -webkit-transform: rotate(40deg);
                -ms-transform: rotate(40deg);
                transform: rotate(40deg);
            }
            .short-text {
                margin-bottom: 32px;
                font-size: 14px;
            }
            .details {
                padding: 26px 0;
                border-top: 1px solid rgba(0, 0, 0, 0.15);
                ul {
                    list-style: none;
                    margin-bottom: 0;
                    padding: 0;
                    line-height: 42px;
                    text-align: left;
                    li.active {
                        color: #282828;
                        position: relative;
                    }
                    li.active:after {
                        content: " \2714";
                        color: #69c47e;
                        font-size: 14px;
                        position: absolute;
                        right: 0;
                    }
                }
            } 
        }
        .octf-btn{
            transition: all 0.3s linear;
            -webkit-transition: all 0.3s linear;
            font-size: 14px;
            -webkit-border-radius: 3px;
            border-radius: 3px;
            padding: 14px 30px;
            line-height: 1.42857143;
            display: inline-block;
            margin-bottom: 0;
            text-decoration: none;
            text-transform: uppercase;
            white-space: nowrap;
            vertical-align: middle;
            font-weight: 600;
            text-align: center;
            background: $primary_color;
            cursor: pointer;
            border: 1px solid transparent;
            color: #fff;
            outline: none;
            position: relative;
        }
        .octf-btn.octf-btn-border {
            background: transparent;
            border-color: $primary_color;
            color: $primary_color;
        }
        .octf-btn.octf-btn-border:hover{
            background: $primary_color;
            color: #fff;
        }
    }
    .ot-pricing-table.is-featured .inner-table{
        color: #fff;
        background: #262832;
        .title-table {
            span {
                background: #fe8423;
            }
        }
        h2{
            color: #fff;
        }
    }
    
}