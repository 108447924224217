.footerSecondContainer {
    // color: $nav-text-color;

    padding-top: 50px !important;
    padding: 0px 10px;

    .apple_icon {
        width: 100%;
    }

    .footerLogoimage {

        object-fit: contain;
        max-width: 200px;
    }

    @media screen and (max-width:768px) {
        .footer-logo-main {
            display: flex;
            flex-direction: column;
            // justify-content: center;
            // max-height: 40px;
            padding-bottom: 10px;
        }
    }

    .footer-details-content {
        // color: #c0c1c2;
        // font-family: Open Sans, sans-serif;
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 0;
    }

    .footer_descip {
        // font-size: $s-size;
        padding-right: 10px;
        padding-top: 5%;
        // font-weight:400;
    }

    .footerHeading {
        font-weight: 700;
        font-size: $m-size;

        padding-bottom: 4%;
    }

}

.footer_headings {
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: #FFFFFF;
    padding-bottom: 15px;

    @media screen and (max-width:600px) {
        padding-top: 20px;
    }

}

.footer_content_main {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 40px;

    @media screen and (max-width:768px) {
        padding-right: 0px;
        display: flex;
        flex-direction: column;
    }
}

.footer_content {
    display: flex;
    flex-direction: row;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #E0E5EB;
    padding: 5px 0px;


    @media screen and (max-width:768px) {

        padding: 0px;
    }

}

.footercategories {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    padding: 30px 0px 40px 0px;
}

.footercat {
    //styleName: Body/Small;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    padding: 10px 0px;
    color: #4E5562;

}

.footercat_space {
    padding: 10px;
    color: #E0E5EB;
}

.footerbotton_content {
    border-top: 1px solid #4E5562;
    padding: 20px 0px 5px;
    color: #FFFFFF;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

}

.websitecopyright {
    font-size: 12px;
    font-weight: 400;
    text-align: left;
}

.footerpay {
    margin-right: 10px;
    width: 58px;
    height: 40px;
}
.footer_right{
    display: flex;
    flex-direction: column;
    align-items: end;

}
.policesfooter{
    @media screen and (min-width:991px){
        padding-left: 130px;
    }
   
}