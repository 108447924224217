
.orderAddressCol{
  
    width: 100%;

    padding-top: 30px;

    .orderAddressImg{
       margin: 20px 0px;
       display: flex;
    
       
        .image{
            width: 30px;
            padding-top: 5px;
           margin-right: 10px;
        }
        .details{
            .add{
                font-size: 12px;
            }
        }
    }
    
  
}
.addresslistdefalut_title{
    display: flex;
    // justify-content: end;
    font-size: 10px;
    border-bottom: 1px solid  #c7c7c7;
    padding-bottom: 3px;
}
.addresscompanylogo{
     width: 50px;
     height: 15px;
     object-fit: contain;
}