.popular_main {
    margin: 10px 0px !important;
}

.popularproductcomp-maindiv {
    height: 100px;
    left: 2px;
    border-radius: 20px;
    box-shadow: 0px 2px 4px 0px #0000001F;
    background: #FFFFFF;
    display: flex;
    align-items: center;
    padding: 10px;
    justify-content: space-between;

    @media only screen and (max-width: 600px) {
        height: 93px;
    }
}

.popularimage_content {
    display: flex;
    flex-direction: row;
}

.popularimage {
    width: 80px;
    height: 80px;

    padding: 5px 3.42px 1.51px 3.09px;
    border-radius: 18px;
    margin-left: 12px;

    @media only screen and (max-width: 600px) {
        height: 69px;
        width: 69px;
    }
}

.popular-middle_items {
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-evenly !important;
    margin: 0px 5px;

    height: 80px;

    @media only screen and (max-width: 600px) {
        height: 66px;
    }

}

.ppularproduct-title {
    font-family: Be Vietnam Pro;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.015em;
    text-align: left;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;

    @media only screen and (max-width: 600px) {
        font-size: 14px;
    }
}

.popularporoduct-brand {
    font-family: Be Vietnam Pro;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: #999CA1;

    @media only screen and (max-width: 600px) {
        font-size: 14px;
    }
}

.popularproduct-price {
    font-family: Be Vietnam Pro;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    padding-right: 10px;
    display: flex;
    justify-content: start;

    @media only screen and (max-width: 600px) {
        font-size: 14px;
    }


}

.popular_product_price_rating {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.popular-product-righticon {
    // margin: 0px 20px;
    text-align: start;
}

// ...............................................mostpopular 

.loadmore-main {
    display: flex;
    justify-content: center;
    align-items: center;
}

.mostpopularloadmore_button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Raleway;
    font-size: 20px;
    font-weight: 700;
    border-bottom: 2px solid black;
    line-height: 24px;
    letter-spacing: 0.015em;
    text-align: center;
    cursor: pointer;
    margin: 38px 0px;

}

/* Sidebar */
.sidebar {
    position: fixed;
    top: 0;
    right: -50%;
    width: 50%;
    height: 100%;
    background-color: #f9f9f9;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
    transition: right 3s ease !important;
    z-index: 1001;
    overflow: scroll;

    @media only screen and (max-width: 991px) {
        right: -100%;
        width: 100%;
    }
}

.sidebar.active {
    right: 0;
    /* Slide in */
}

/* Overlay */
.overlay {
    position: fixed !important;
    top: 0;
    left: 0;
    width: 50% !important;
    height: 100% !important;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000 !important;
    backdrop-filter: blur(5px);
    pointer-events: none !important;
    cursor: not-allowed !important;
    pointer-events: none !important;
    user-select: none !important;
    touch-action: none !important;

}

/* Close button */
.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: red;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
}

.add-to-cartbtn {
    width: fit-content;
    padding: 5px 15px;
    border-radius: 5px;
    cursor: pointer;
}

//.............................................................productdetail sidebar
.cart_sidebar_active {
    display: flex;
    justify-content: start;
    flex-direction: row;
    background: #FFFFFF;
    padding: 10px 15px;
    margin-left: unset !important;
    margin-right: unset !important;
    z-index: 999px;
}

.sidebaradded_tocart_title {
    font-family: Be Vietnam Pro;
    font-size: 18px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    padding-right: 10px;
    margin-top: 10px;
    @media only screen and (max-width: 991px) {
        font-size: 12px;

    }
}

.closeicon_productdetail_sidebar {
    font-size: 25px;
    text-align: end;
    cursor: pointer;
}

.Productdetail_image {
    width: 120px;
    height: 120px;
    @media only screen and (max-width: 991px) {
        width: 100px;
        height: 100px;

    }
}

.cart_subtotal_title {
    font-family: Raleway;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 10px;
    @media only screen and (max-width: 991px) {
        font-size: 12px;

    }

}

.sidebar_title_image {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 5px;
}

.sidebarcart-image {
    width: 100%;
    height: 100%;
    background: #FCF7DE;
    object-fit: contain;
}

.sidebar_lastdiv {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.cartsidebar_righticon {
    color: darkgreen;
    font-size: 25px;
    margin: 10px 2px;
    @media only screen and (max-width: 991px){
        margin: 23px -3px;
    }

}

.prices_idebar {
    font-family: Be Vietnam Pro;
    font-size: 18px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    padding-right: 10px;
    margin-top: 10px;
    color: #999CA1;
}

.sidebar_cartbutton {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #000000;
    padding: 9px 0px;
    border-radius: 4px;
    font-family: Be Vietnam Pro;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 3px;
    line-height: 20px;
    text-align: left;
    @media only screen and (max-width: 991px) {
        font-size: 10px;

    }


}

.cartsidebar_button {}

.checkout_sidebar_button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 4px;
    font-family: Be Vietnam Pro;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    padding-right: 10px;
}

.Downcartsidebar {
    background: #FFFFFF;
    margin: 10px;
}

.cat_sidebar_saveextra_title {
    font-family: Be Vietnam Pro;
    font-size: 24px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    padding-right: 10px;
    color: #000000;
    padding: 10px;
}
.ordexvxxvxv{
    position:  absolute !important;
}