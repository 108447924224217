.product-brand {
    width: 114px;
    height: 40px;
    gap: 0px;
    //styleName: Body Text/12px - Regular;
    font-family: Be Vietnam Pro;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;

}

.product-list {
    padding-left: 5px !important;
    padding-right: 5px !important;

    @media only screen and (max-width: 991px) {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
}

.productcard1_producttitle {
    max-width: 100% !important;
}

//..................................................listing page
.listing-products {
    // padding: 20px 60px !important;
    // background: #F6FCFF;
    margin: 12px !important;

    @media only screen and (max-width: 991px) {
        // padding: 0px 0px !important;
        // background: #F6FCFF;
        margin: 0px !important;
    }
}
