.rated-and-review-page {
    // box-shadow: 0 0px 3px 1px rgba(0,0,0,.4);
    // border: 1px solid blue;
    // font-family: $font-family-primary !important;
    margin-bottom: 10px;
    box-shadow: 0 3px 16px 0 #0000001c;
    .ratedAndReview {
        padding: 10px;
        // box-shadow: 0 1px 3px -1px rgba(0,0,0,.4);

        
        padding-bottom: 30px;
        margin-bottom: 30px;

        .ratedProduct-main {
            // border: 1px solid lightgray;
            // border-bottom: 1px solid lightgray;

            padding: 10px 0px;

            .ratedProduct-title {
                font-size: 16px;
                font-weight: 500;
                // color: $paragraph-color;
                // font-family: $font-family-2;

            }

            .ratedProduct-rating {
                display: flex;
                padding-top: 10px;
                padding-bottom: 10px;

                .ratedProduct-stars {
                    display: flex;

                    flex-grow: 1;

                    align-content: center;
                    text-align: center;

                    .ratedProduct-star {
                        font-size: 20px;
                        padding-right: 20px;
                        color: $primary_color;
                    }

                    .ratedProduct-stars-explanation {
                        font-size: 16px;
                        font-weight: 700;
                        padding-top: 5px;
                    }
                }

            }

            .ratedProduct-details {
                font-size: 14px;
                // font-family: $font-family-2;
                color: $primary_color;
            }

            .starsStatus {
                font-size: 14px;
                font-weight: 500;
                padding-left: 10px;
                // font-family: $font-family-2;
                color: $paragraph-color;
            }
        }

        .reviewProduct-main {
            // box-shadow: 0 1px 3px 1px rgba(0,0,0,.4);

            padding: 10px 0px;

            .reviewProduct-title {
                font-size: 16px;
                font-weight: 500;
                padding: 0px 0px 0px 0px;
                // font-family: $font-family-2;
            }

            .discriptionAndTitle {
                border: 1px solid lightgray;
                margin-top: 10px;

                .description-main {
                    padding: 10px;
                    display: flex;
                    font-size: 12px;
                    color: $primary_color;

                    .description-name {
                        display: flex;
                        flex-grow: 1;
                    }

                    .description-status {}
                }

                .description-inputbox {
                    width: 100%;
                    height: 120px;
                    border: none;
                    outline: none;
                    padding-left: 10px;

                }

                .title-main {
                    .title-name {
                        font-size: 12px;
                        color: $primary_color;
                        padding: 10px;
                    }

                }

                .title-inputbox {
                    width: 100%;

                    border: none;
                    outline: none;
                    padding-left: 10px;
                    padding-bottom: 10px;

                }
            }
        }

        .related-upload-photo {
            width: 100px;
            height: 100px;
            padding: 7px;
            object-fit: contain;
        }

        .ratedproduct-upload-image-title {
            padding-left: 10px;
            font-size: 16px;
            font-weight: 500;
            // font-family: $font-family-2;
        }

        .related-review-submission {
            display: flex;
            padding: 10px;
            flex-wrap: wrap;

        }

        .related-submited-button {
            // background: $primary_color;
            padding: 15px;
            
            // color: $white;
            // font-family: $font-family-2;
            text-align: center;
            float: right;
            width: 200px;
            border: none;

    
            border-radius: 5px;
            cursor: pointer;
            font-size: 15px;
            font-weight: 600;
            margin-right: 15px;
            padding: 12px 5px 7px;
           
       


        }

    }
}



.rated-images-display {
    display: flex;
    flex-wrap: wrap;
    object-fit: contain;
    padding: 5px;
    // font-family: $font-family-primary !important;

    .ratedphoto {
        border: 1px solid green;
        padding: 10px;
    }

}

.upload-images-in-form {
    display: block;
    // width: 100% !important;
    height: fit-content;
    position: relative;
    display: flex !important;
    flex-wrap: wrap !important;
}

.upload-images-only-five {
    color: red;
    font-size: 14px;
    font-weight: 600;
    padding-left: 10px;
}

.ratedproduct-images- {
    display: flex;
    justify-content: space-between;
}

.image-remove-ratedproduct {
    border: 1px solid black;
    border-radius: 5px;
    padding: 5px;
    font-size: 12px;
    margin-right: 5px;
    font-weight: 600;
    cursor: pointer;
}

@media only screen and (max-width: 500px) {
    .related-upload-photo {
        width: 50px !important;
        height: 50px !important;
        padding: 7px;
        object-fit: contain;
        display: flex;
        flex-wrap: wrap;
        // border: 1px solid black;
    }
}

@media only screen and (min-width: 500px) {
    .related-upload-photo {
        width: 100px;
        height: 100px;
        padding: 7px;
        object-fit: contain;
    }
}

.removeimages-in-ratedproducts {
    height: 15px;
    position: absolute;
    z-index: 1;
    top: 0%;
    left: 80%;
    // float: right !important;
    // display: flex!important;
    // justify-content: end !important;
    // text-align: end !important;
    // align-items: end !important;
    // flex-direction: row-reverse;
    // flex-direction: column-reverse;
    // display: flex;
    // justify-content: end;
}


.ratedproduct-leftside {
    // font-family: $font-family-primary !important;
    box-shadow: 0 1px 3px -1px rgba(0, 0, 0, .4);

    .rating-review {
        font-size: 24px;
        font-weight: 700;
        padding: 20px 10px;
        // padding-bottom: 20px;
    }

    .ratedproduct-leftside-title {
        font-size: 16px;
        font-weight: 500;
        // font-family: $font-family-2;
        padding: 10px;
        box-shadow: 0 1px 3px -1px rgba(0, 0, 0, .4);
    }

    .ratedproduct-leftside-title-subtitle {
        padding: 15px 10px;
        box-shadow: 0 1px 3px -1px rgba(0, 0, 0, .4);

        .ratedproduct-leftside-title-subtitle-title {
            font-size: 16px;
            font-weight: 500;
            padding-bottom: 10px;
            // font-family: $font-family-2;
        }

        .ratedproduct-leftside-title-subtitle-title-content {
            font-size: 14px;
            color: $paragraph-color;
            // font-family: $font-family-2;
        }
    }
}

.title-and-image {
    padding: 10px 0px;
    // box-shadow: 0 3px 16px 0 #0000001c;
    margin: 10px 0px 0px 0px !important;

    .rating-reviw-title-main-div {
        // font-family: $font-family-primary !important;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        text-align: center;

        .rating-review {
            font-size: 22px;
            font-weight: 600;
            // margin:  25px 0px;
        }
    }



    .rated-image-maindiv {
        display: flex;
        justify-content: start;
        flex-direction: row;
        align-items: center;




        .ratedimage-title {
            font-size: 14px;
            // overflow: hidden;
            // text-overflow: ellipsis;
            // -webkit-line-clamp: 2;
            // -webkit-box-orient: vertical;
            // white-space: nowrap;
            width: 100%;
            padding: 0px 10px;
        }

        .ratedimage {
            aspect-ratio: 3/4;
            height: 60px;
            width: auto;
        }
    }

    @media screen and (max-width: 991px) {
        .rated-image-maindiv{
            display: flex;
            justify-content: start;
            flex-direction: row;
          align-items: center;
        }
        }
}
.rate-product-maim{
    display: flex;
    flex-direction: row;
    // justify-content: space-between;
}
.rating-error{
    font-size: 12px;
    // font-family: $font-family-2;
    color: red;
    font-weight: 500;
    padding-left: 10px;

}
.preverating{
    color: lightgray;
}
.Ratingimages{
    width: 50px;
    margin: 5px;
}
.ratindandreviewdate{
    padding-left: 5px;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.rating-reviews-allimages{
    width: 100%;
    object-fit: cover;
}
.rating-and-review{
    // background:black !important;
    padding: 15px !important;
}
.reviewimages{

 margin: 0px 0px 20px 0px !important;
   
}
.reviewimagestitle{
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 10px;
}
.progress-fill{
    // width: 50% !important;
}
