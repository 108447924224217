.ratingNewSection {
    background: #C23A971A;
    padding: 15px 10px;
    margin-top: 30px;
}

.rating_and_review_title {
    font-family: Raleway;
    font-size: 20px;
    font-weight: 800;
    line-height: 26px;
    letter-spacing: -0.20000000298023224px;
    text-align: left;

}

.Ratingnew_star_icons {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0px 0px 15px 0px;
}
.individual_Ratingnew_star_icons{
    display: flex;
    flex-direction: row;
}

.ratingprofileimage {
    margin-right: 10px;
}
.averagerating{
    font-family: Raleway;
font-size: 14px;
font-weight: 700;
line-height: 18px;
letter-spacing: -0.14000000059604645px;
text-align: right;
margin-left: 10px;

}
.rating_image {
    width: 100%;
    width: 50px;
    height: 50px;
    padding: 4px;
    border-radius: 1000px;
    background: #fff;
    object-fit: contain;
}
.Rating_viewall_button{
    display: flex;
    justify-content: center;
    padding: 10px;
    border-radius: 10px;
    font-family: Nunito Sans;
font-size: 16px;
font-weight: 300;
cursor: pointer;
line-height: 25px;
text-align: center;

}

.ratingnew_data {
    display: flex;
    flex-direction: row;
    padding: 5px 0px;
}

.cusromername {
    font-family: Raleway;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.1599999964237213px;
    text-align: left;

}

.review_data {
    font-family: Nunito Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    margin: 5px 0px 10px 17px;
    

}