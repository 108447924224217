.mobile-header {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    // background: $header-bg;
    align-items: center;
    position: sticky;
    box-shadow: none;
    justify-content: space-evenly;
    padding-top: 7px;

    padding-bottom: 7px;
    // background: linear-gradient(90deg, #C23A97 0%, #192253 100%);

    .header-item-top2 {
        height: 44px;
    }

    .logo {
        margin-top: 4px;
        margin-left: 10px;

        img {
            object-fit: contain;
            width: 220px;
            height: 56.83px;


        }
    }

    .header_title {
        margin-top: 11px;
        margin-left: 15px;
    }

    .searchbar {
        margin-left: 15px;
        margin-right: 15px;
        padding-bottom: 15px;
    }

    .header-item-top {
        display: flex;
        margin-left: 15px;
        margin-right: 15px;
        justify-content: space-between;
    }

    .header-address {
        display: flex;
        margin-left: 15px;

        .location-marker {
            margin-top: 13px;
            margin-right: 4px;
            color: $white;
        }

        .header-address-line1 {
            color: $gray-20;
            font-size: $xs-size;
        }

        .header-address-line2 {
            font-size: $s-size;
            font-weight: 400;
            color: $white;
        }
    }

    .dropdown-up-title {
        color: $white;
        font-size: $xs-size;
    }

    #nav-dropdown-dark-example {
        color: $white;
        font-size: $s-size;
    }

    #nav-dropdown-search {
        color: $black;
        font-size: $s-size;
    }

    #search-button {
        background-color: $primary_color;
    }

    .input-group-text {
        border: none;
    }

    .cart {
        margin: 15px 10px 10px 10px;

        .custom-badge {
            // background: $primary_color;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0px 5px;
            position: absolute;
            width: 24px;
            height: 24px;
            border: 3px 0px 0px 0px;
            margin: -34px 0px 0px 20px;
        }
    }
}

.navbar {
    border-bottom: solid 1px #EBEBEB;
}

.bottom-tab-nav {
    border-top: solid 1px #EBEBEB;
}

.nav-link,
.bottom-nav-link {
    color: #55575b;
}

.bottom-nav-link.active {
    color: #922c88;
}

.bottom-tab-label {
    font-size: 12px;
}

.bottom-tabs {
    background-color: $white;
    box-shadow: rgba(34, 34, 34, 0.12) 0px 2px 16px 2px;
    padding: 0px 16px 2px !important;
    min-height: 48px;
}

.mobile_searchbar {
    padding: 0px 24px;
    margin-top: 10px;
}

.mobileHeader-icons {
    display: flex;
    justify-content: center;
    align-items: baseline;
}

.Mobileheader_main_status {
    background: #fff;
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 14px;
    color: #999CA7;
}

.searchtag_main {
    padding-left: 5px;
}